import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Flex, Text, Button, List, ListItem, ListIcon, Spinner, useToast } from "@chakra-ui/react"
import { loadStripe } from "@stripe/stripe-js"
import WraityClient from '../../clients/wraity-client'
import { CheckIcon, CheckCircleIcon } from '@chakra-ui/icons'

const today = new Date()
const lastMay = new Date(today.getFullYear(), 4, 30)
const passedDeadline = today > lastMay


function PricingTable({isMobile}) {

  const toast = useToast()

  const { accessToken } = useSelector(state => state.user)

  const [isLoading, setIsLoading] = useState(false)

  const getStripe = () => {
    const devMode = process.env.NODE_ENV === 'development'
    const apiKey = devMode ? process.env.REACT_APP_STRIPE_TEST_API_KEY : process.env.REACT_APP_STRIPE_API_KEY
    const stripePromise = loadStripe(apiKey)
    return stripePromise
  }

  const redirectToCheckout = async () => {
    setIsLoading(true)
    try {
      const stripe = await getStripe()
      const wraityClient = new WraityClient(accessToken)
      const response = await wraityClient.createStripeSession()
      const data = await response.json()
      const sessionId = data.sessionId

      stripe.redirectToCheckout({
        sessionId: sessionId
      });
    } catch (error) {
      setIsLoading(false)
      toast({
        title: 'Une erreur est survenue',
        description: "Veuillez réessayer plus tard.",
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }

  }


  return (
    <Flex w='90%'
                flexDirection={['column-reverse', 'column-reverse', 'row']} mx='auto'
          >
            {!isMobile &&
            <Flex w={['90%', '90%','45%']} mx='auto' border='2px' borderColor='gray.300' borderRadius={16} p={6}
                  flexDirection='column' my={[4, 0]} alignItems='flex-start' justifyContent='right'
            >
              <Text fontSize='xl' fontWeight='bold'>
                Gratuit
              </Text>
              <Text fontSize='sm' color='grey'>
                Accès gratuit avec un nombre de mots restreint.
              </Text>
              <Button isDisabled={true} colorScheme='gray' onClick={redirectToCheckout} mt={4} mb={8}>
                <Text size='xl'>
                  Acheter
                </Text>
              </Button>
              <List spacing={3}>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray.300' />
                  GPT-3.5-turbo
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray.300' />
                  2000 mots gratuits
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray.300' />
                  Génération d'articles un par un
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray.300' />
                  Un seul espace de travail
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray.300' />
                  Générateur de sujets à traiter
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray.300' />
                  Scanner de site
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray.300' />
                  Maillage interne entre les articles
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray.300' />
                  Génération de publications réseaux sociaux
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckIcon} color='gray.300' />
                  Génération de meta descritpion et balise title
                </ListItem>
              </List>
            </Flex>
            }
            <Flex w={['90%', '90%','45%']} mx='auto' border='2px' borderColor={'brand.primary'} borderRadius={16} p={6}
                  flexDirection='column' my={[4, 0]} alignItems='flex-start' justifyContent='right'
            >
              <Text fontSize='xl' fontWeight='bold'>
                Formule Pro - paiement unique
              </Text>
              <Text fontSize='sm' color='grey'>
                La formule recommandée pour tirer le maximum de CopyWraiter.
              </Text>
              {!passedDeadline &&
                <>
                  <Text as='s' fontSize='xl' mt={2} fontWeight='bold'>
                      79€
                  </Text>
                  <Text fontSize='2xl' mt={1} fontWeight='bold'>
                      59€ <span style={{fontSize: "18px", fontWeight: 400}}>- avec le code promo CW2</span>
                  </Text>
                </>
              }
              {passedDeadline &&
                <Text fontSize='2xl' mt={1} fontWeight='bold'>
                  59€
                </Text>
              }
              <Flex flexWrap={'wrap'} alignItems='center'>
                <Button isDisabled={isLoading}
                        backgroundColor={'brand.primary'}
                        color='white'  my={4}
                        onClick={() => redirectToCheckout()}
                        _hover={{
                          background: 'brand.secondary',
                          color: "white",
                        }}
                >
                  {isLoading && <Spinner size='xs' mr={2}/>}
                  <Text size='xl'>
                    Acheter
                  </Text>
                </Button>
              </Flex>
              <List spacing={3}>
                <ListItem fontWeight="semibold">
                  <ListIcon as={CheckCircleIcon} color='green.500' />
                  Toute les fonctionnalités de la formule gratuite
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color='green.500' />
                  GPT-4-turbo
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color='green.500' />
                  Nombre de mots illimité
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color='green.500' />
                  Génération d'articles en simultané
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color='green.500' />
                  Plusieurs espaces de travail
                </ListItem>
              </List>
              <Text mt={6} color="gray.600">
                <span style={{fontWeight: "bold"}}>Attention :</span> pour utiliser CopyWraiter en illimité il vous faudra fournir votre clef API OpenAI. Prévoyez donc environ 0,10€ par article généré (si vous rallongez plusieurs fois l'article) pour l'utilisation de l'API OpenAI.
              </Text>
            </Flex>
          </Flex>
  )
}

export default PricingTable
