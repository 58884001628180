export const updateUser = (user) => (
  {
    type: 'updateUser',
    payload: user
  }
)

export const logOutUser = () => (
  {
    type: 'logOutUser',
  }
)

export const setUserUsage = (usage) => (
  {
    type: 'setUserUsage',
    payload: usage
  }
)

export const setHasTokens = (bool) => (
  {
    type: 'setHasTokens',
    payload: bool
  }
)

export const updateUserToken = (token) => (
  {
    type: 'updateUserToken',
    payload: token
  }
)

export const setHasBought = (bool) => (
  {
    type: 'setHasBought',
    payload: bool
  }
)

export const setCustomerId = (customerId) => (
  {
    type: 'setCustomerId',
    payload: customerId
  }
)
