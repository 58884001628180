import { Tabs, TabList, Tab } from '@chakra-ui/react'

function Nav({task, setActiveSection}) {

  return (
    <Tabs size='md' defaultIndex={task.status === 'completed' ? 1 : 0}>
      <TabList>
        <Tab onClick={() => {setActiveSection('edit')}}>Formulaire</Tab>
        <Tab isDisabled={task.article === ""} onClick={() => {setActiveSection('results')}}>Article</Tab>
        <Tab isDisabled={task.article === ""} onClick={() => {setActiveSection('meta')}}>Meta données</Tab>
        <Tab isDisabled={task.article === ""} onClick={() => {setActiveSection('social-networks')}}>Réseaux Sociaux</Tab>
        <Tab onClick={() => {setActiveSection('delete')}}>Supression</Tab>
      </TabList>
    </Tabs>
  )
}

export default Nav
