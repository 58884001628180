import { useState } from "react"
import { db } from "../../../config/firebase-config"
import { doc, deleteDoc } from "firebase/firestore"
import { useDispatch } from "react-redux"
import { setDisplayTaskModal } from "../../../redux/actions/modalsActions"
import { Box, Flex, Button, Spinner} from "@chakra-ui/react"


export default function TaskDelete({kanbanId, task}) {

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)


  const deleteTask = async () => {
    setIsLoading(true)
    await deleteDoc(doc(db, 'kanbans', kanbanId, 'tasks', task.id))
    await deleteDoc(doc(db, 'social-networks-posts', task.id))
    dispatch(setDisplayTaskModal(false))
  }

  return (
    <>
    {isLoading ?
      <Flex w='100%' h='100%' alignItems='center' justifyContent='center'>
        <Spinner size='xl' color='grey'/>
      </Flex>
      :
      <Box w='100%' h='100%' textAlign='center' p='2'>
        <h1>Voulez-vous supprimer cet article ? </h1>
        <p>Toutes les données liées à cet article seront supprimées définitivement.</p>
        <Button mt='4'  colorScheme='red' onClick={deleteTask}> Supprimer</Button>
      </Box>
    }
    </>
  )
}
