import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import Layout from './components/Layout'
import Kanban from './components/Kanban'
import LoginRegister from './components/LoginRegister'
import ErrorPage from './components/Error'
import store from './redux'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import '@stripe/stripe-js'
import Success from './components/Subscription/Success';
import Dashboard from './components/Dashboard';
import SubLayout from './components/Layout/SubLayout';
import PricingTable from './components/PricingTable';
import ResetPasword from './components/ResetPassword';
import AccountPage from './components/Account';

const router = createBrowserRouter([
  {
    path: "/app/",
    element: <Layout />,
    children: [
      {
        path: 'kanban/:id',
        element: <Kanban />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'account',
        element: <AccountPage />
      },

    ],
    errorElement: <ErrorPage />
  },
  {
        path: 'pricing-table',
        element: <PricingTable />
      },
  {
    path: '',
    element: <SubLayout />,
    children: [
      {
        path: '/login',
        element: <LoginRegister mode='login'/>
      },
      {
        path: '/register',
        element: <LoginRegister mode='register' />
      },
      {
        path: '/password-reset',
        element: <ResetPasword />
      },
      {
        path: '/',
        element: <LoginRegister mode='login'/>
      },
    ],
    errorElement: <ErrorPage />
  },
  {
    path: '/success',
    element: <Success />,
    errorElement: <ErrorPage />
  },
])

const theme = extendTheme({
  colors: {
    brand: {
      primary: "#08415C",
      secondary: "#00B9AE",
    },
    switch: {500: "#08415C", 600: '#00B9AE'}
  },
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </Provider>
);
