export const resetKanban = () => (
  {
    type: "resetKanban"
  }
)

export const setKanbanId = (id) => (
  {
    type: 'setKanbanId',
    payload: id
  }
)

export const setSelectedTaskId = (id) => (
  {
    type: 'setSelectedTaskId',
    payload: id
  }
)

export const setTasks = (tasks) => (
  {
    type: 'setTasks',
    payload: tasks
  }
)

export const setKanbanColumns = (columns) => (
  {
    type: 'setKanbanColumns',
    payload: columns
  }
)

export const setScanData = (articles) => (
  {
    type: 'setScanData',
    payload: articles
  }
)

export const setDomain = (domain) => (
  {
    type: 'setDomain',
    payload: domain
  }
)

export const setIsScanning = (isScanning) => (
  {
    type: 'setIsScanning',
    payload: isScanning
  }
)

export const setLastScan = (lastScan) => (
  {
    type: 'setLastScan',
    payload: lastScan
  }
)
