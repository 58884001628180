import styled, { keyframes } from 'styled-components';
import { CloseButton, Spinner, Text } from '@chakra-ui/react'


const appearFromBottom = keyframes`
  from {
    transform: translateY(100vh);
  } to {
    transform: translateY(0vh);
    opacity: 1;
  }
`

const Wrapper = styled.div`
  background-color: rgba(0,0,0,0.8);
  width: 100vw;
  height: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  animation: ${props => props.$animated ? appearFromBottom : 'none' };
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  background-color: white;
  height: ${props => props.$size === "lg" ? '92%' : '300px' };;
  width: 85%;
  border: 2px solid white;
  border-radius: 10px;
  z-index: 3;
  overflow-y: hidden;
  padding: 30px;
  position: relative;

  h1 {
    margin: 10px 0;
    font-size: 20px;
    font-weight: bold;
  }


  @media (max-width: 768px) {
    width: 90%;
    padding: 20px;
  }
`

const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-y: auto;
`

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

function Modal({children, isLoading, loadingText, closeModal, animated, size}) {

  const handleWrapperClick = (e) => {
    // e.stopPropagation()
    // closeModal()
  }


  return (
    <Wrapper onClick={(e) => {handleWrapperClick(e)}}>
        <ModalContainer $isLoading={isLoading} $animated={animated} $size={size ? size : "lg"}onClick={(e)=>{e.stopPropagation()}}>
          <CloseButton onClick={() => closeModal()} colorScheme='gray' size='lg' position='absolute' top='10px' right='10px'/>
          {isLoading ?
            <ModalContent>
              <Text fontSize='2xl' fontWeight={'bold'} m={4}>{loadingText}</Text>
              <SpinnerContainer>
                <Spinner size='xl' color='grey'/>
              </SpinnerContainer>
            </ModalContent>
            :
            <ModalContent>
              {children}
            </ModalContent>
          }
        </ModalContainer>
    </Wrapper>
  )

}

export default Modal
