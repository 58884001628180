import { Input, Button, Text, useToast, Box } from '@chakra-ui/react'
import { useState } from 'react'

function IdeasForm({getIdeasFromOpenai, setGeneratedIdeas, setIsLoading}) {

  const [value, setValue] = useState("")

  const toast = useToast()


  const handleClick = async () => {
    if (value !== "") {
      setIsLoading(true)
      const newValues = value.split(',').map(item => item.trim())
      try {
        const ideas = await getIdeasFromOpenai(null, newValues)
        setGeneratedIdeas(ideas)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
      }
    } else {
      toast({
        title: 'Veuillez renseigner au moins un thème.',
        description: "Séparez vos thèmes par des virgules.",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  return (
    <Box h='100%' overflowY='auto'>
      <Text fontSize='2xl' fontWeight={'bold'} pt={[4, 0]} m={4}>Il n'y a pas encore d'articles* dans votre espace de travail...</Text>
      <Text fontSize='md' m={4}>
        Comme vous n’avez pas encore créé d’articles, il est difficile de vous
        faire des suggestions pertinentes. Pour cela nous avons besoin que vous
        indiquiez un thème sur lequel vous voulez avoir des suggestion de sujets d’articles :
      </Text>
      <Input name="themes" value={value}
            onChange={(e) => {setValue(e.target.value)}}
            placeholder='Exemple : Crypto, Immobilier, Économie circulaire…'
            py={[4, 0]}
      />
      <Text fontSize='md' m={4}>
        ⚠️ Si vous voulez soumettre plusieurs thèmes, veillez à bien les séparer par des virgules.
      </Text>
      <Button bg='brand.primary' color='white' _hover={{ background: "brand.secondary"}} py={[4, 0]} width='200px' my={8} onClick={handleClick} size={'md'}>
        Générer des idées
        </Button>
      <Text fontSize='md' m={4} color="grey">
        <strong>*</strong>Nous avons besoin d’au moins un sujet d’article pour vous faire des suggestions pertinentes.
        Si votre espace de travail contient des articles,
        veillez à remplir le champs "Sujet" dans le formulaire d’édition de ces articles.
      </Text>
    </Box>
  )
}

export default IdeasForm
