import { useEffect } from "react";
// import WraityClient from "../../clients/wraity-client";
import { useNavigate } from "react-router-dom";
import { Flex, Text, Button } from "@chakra-ui/react";

function Success() {

  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/app/dashboard')
  }

  // const getSessionData = async () => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const sessionID = urlParams.get('session_id')
  //   const wraity = new WraityClient()
  //   const response = await wraity.retrieveStripeSession(sessionID)
  //   const sessionData = await response.json()
  //   window.rewardful('convert', { email: sessionData.customer_details.email });
  // }

  useEffect(() => {
    // getSessionData()
  }, [])

  return (
    <Flex width='100%' p={8} flexDirection='column' alignItems='center'>
        <Text fontSize="3xl" my={4}>
          Votre paiement est confirmé.
        </Text>
        <Text fontSize="3xl" fontWeight='bold' my={4}>
          Merci 🙏
        </Text>
        <Button my={4} bg='brand.primary' color='white' _hover={{ background: "brand.secondary"}} onClick={handleClick}>
          Revenir à CopyWraiter
        </Button>
    </Flex>
  )
}

export default Success
