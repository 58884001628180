import { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Flex, Box, Text, Tooltip, Button, Input, useToast, Progress} from "@chakra-ui/react"
import { InfoIcon } from "@chakra-ui/icons"
import { doc, setDoc, onSnapshot } from "firebase/firestore"
import { db } from "../../config/firebase-config"
import WraityService from "../../services/wraity"
import { setDisplayScan, setDisplaySubscriptionModal, setDisplayApiModal } from "../../redux/actions/modalsActions"
import { setDomain, setIsScanning, setLastScan } from "../../redux/actions/kanbanActions"
import { debounce } from "lodash"

function Navbar() {

  const toast = useToast()
  const dispatch = useDispatch()

  const { kanbanId, domain, isScanning, lastScan } = useSelector(state => state.kanban)
  const { accessToken, hasBought, usage } = useSelector(state => state.user)

  const [tokensLeft, setTokensLeft] = useState(0)

  const [width, setWidth] = useState(window.innerWidth)

  const unsubscribe = useRef(() => {})

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isTablet = width <= 1280 && width >= 700;
  const isDesktop = width >= 1280

  useEffect(() => {
    return () => {
      if (unsubscribe.current) {
        unsubscribe.current()
      }
    }
  }, [])

  useEffect(() => {
    let consuption = usage * 0.75 / 2000
    if (consuption === 0) {
      setTokensLeft(100)
    } else {
      setTokensLeft(consuption >= 1 ? 0 : Math.floor((1 - consuption) * 100))
    }
  }, [usage])

  const saveDomain = debounce(async (value) => {
    await setDoc(doc(db, 'kanbans', kanbanId), {domain: value}, {merge: true})
  }, 600)

  const handleChange = async (value) => {
    dispatch(setDomain(value))
    await saveDomain(value)
  }

  const startScan = async () => {
    unsubscribe.current = onSnapshot(doc(db, "kanbans", kanbanId), (doc) => {
    const data = doc.data()
      if (data !== undefined) {
          dispatch(setIsScanning(data.isScanning !== undefined ? data.isScanning : false ))
          dispatch(setLastScan(data.lastScan !== undefined ? new Date(data.lastScan) : ''))
          dispatch(setDomain(data.domain ? data.domain : ''))
      }
    });
    await setDoc(doc(db, 'kanbans', kanbanId), {isScanning : true}, {merge: true})
    const wraityService = new WraityService(accessToken)
    try {
      await wraityService.scanWebsite(domain, kanbanId)
    } catch (error) {
      toast({
        title: 'Impossible de scanner votre site.',
        description: "Vérifiez votre nom de domaine. Autrement, il se peut que votre site ne soit pas lisible par CopyWraiter.",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }

  }

  const handleScanClick = async () => {
    await startScan()
  }

  const handleBuyClick = () => {
    // TODO : display buy modal
    dispatch(setDisplaySubscriptionModal(true))
  }

  const openApiModal = () => {
    dispatch(setDisplayApiModal(true))
  }

  return (
    <Flex h='50px' w='100%' pl={6} pr={12} alignItems='center'
          justifyContent='space-between'
          boxShadow='lg' bgColor='gray.200'
    >
      <Flex alignItems='center' w='auto'>
        <Tooltip label="Scanner votre site permet à CopyWraiter de prendre connaissance de vos articles déjà publiés. Si vous avez déjà effectué un scan, cliquez sur la date à droite du bouton pour voir les articles pris en compte.">
          <InfoIcon color="gray.400"/>
        </Tooltip>
        <Input size='sm' w='180px' ml={4} bgColor='white' borderRadius={4} border='gray.400'
              placeholder="copywraiter.io"
              value={domain}
              isDisabled={isScanning}
              onChange={(e) => {handleChange(e.target.value)}}
        />
        <Tooltip label="Votre premier scan peut prendre un peu de temps en fonction du nombre d'articles à analyser. Le scan continuera même si vous quittez l'application.">
          <Button size='sm' colorScheme="switch" ml={2}
                  onClick={handleScanClick}
                  isLoading={isScanning}
                  loadingText='Scan en cours'
          >
            Scanner le site
          </Button>
        </Tooltip>
        {lastScan && lastScan !== '' && !isScanning &&
          <Button variant='link' size='sm' fontStyle='italic' ml={2}
                  onClick={() => {dispatch(setDisplayScan(true))}}
          >
            {`Dernier scan : ${('0' + (new Date(lastScan).getDate())).slice(-2)}/${('0' + (new Date(lastScan).getMonth()+1)).slice(-2)}/${new Date(lastScan).getFullYear()}`}
          </Button>
        }
      </Flex>
      {!hasBought && isTablet &&
        <Flex alignItems='center' w='auto'>
          <Box mr={4}>
              <Text>{tokensLeft}% des mots gratuits restants</Text>
          </Box>

          <Button size='sm' colorScheme="switch" onClick={handleBuyClick}>
            Acheter
          </Button>

        </Flex>
      }
      {!hasBought && isDesktop &&
        <Flex alignItems='center' w='auto'>
          <Box w='200px' mr={4}>
              <Tooltip label={`Il vous reste ${tokensLeft}% de vos mots gratuits.`} >
                <Progress hasStripe colorScheme={tokensLeft >= 50 ? 'green' : tokensLeft > 20 ? 'orange' : 'red'} backgroundColor='white' value={tokensLeft} />
              </Tooltip>
          </Box>
          <Button size='sm' colorScheme="switch" onClick={handleBuyClick}>
            Acheter
          </Button>
        </Flex>
      }
      {hasBought &&
            <Button size='sm' mx={1} onClick={openApiModal}>
              Gérer ma clef API
            </Button>
          }
    </Flex>
  )
}

export default Navbar
