import produce from 'immer'

const defaultState = {
  uid: '',
  email: '',
  accessToken: '',
  usage: 0,
  hasTokens: false,
  hasBought: false,
  showTutorial: false,
  customerId: null
}

function userReducer(state = defaultState, action) {

  switch(action.type) {
    case 'updateUser':
      return produce(state, (draft) => {
        draft.uid = action.payload.uid
        draft.email = action.payload.email
        draft.accessToken = action.payload.accessToken
      })

    case 'logOutUser':
      return produce(state, (draft) => {
        draft.uid = ""
        draft.email = ""
        draft.accessToken = ""
      })

    case 'setUserUsage':
      return produce(state, (draft) => {
        draft.usage = action.payload
      })

    case 'setHasTokens':
      return produce(state, (draft) => {
        draft.hasTokens = action.payload
      })

    case 'updateUserToken':
      return produce(state, (draft) => {
        draft.accessToken = action.paylaod
      })

    case 'setHasBought':
      return produce(state, (draft) => {
        draft.hasBought = action.payload
      })

    case 'setCustomerId':
      return produce(state, (draft) => {
        draft.customerId = action.payload
      })

    default:
      return state
  }


}

export default userReducer
