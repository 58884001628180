import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Text,
  Box,
  Flex,
  Checkbox,
  Switch,
  Tooltip,
  useToast,
  Tag,
  TagLabel,
  TagCloseButton
} from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDisplayTaskModal, setDisplaySubscriptionModal} from "../../../redux/actions/modalsActions"
import styled from 'styled-components'
import { db } from '../../../config/firebase-config'
import { doc, setDoc } from 'firebase/firestore'
import WraityService from '../../../services/wraity'
import { debounce } from 'lodash'


const StyledForm = styled.form`
  width: 100%;
  height: 100%;
`

function TaskForm({ task, setTask, setIsLoading }) {

  const dispatch = useDispatch()
  const toast = useToast()

  const { tasks, kanbanId, selectedTaskId, scanData } = useSelector(state => state.kanban)
  const { accessToken, hasTokens, hasBought } = useSelector(state => state.user)

  const [inputValues, setInputValues] = useState(task.form)
  const [newKeyword, setNewKeyword] = useState('')

  const [keywords, setKeywords] = useState(task.form.keywords ? task.form.keywords : [])

  useEffect(() => {
    const debouncedSave = debounce(async (value) => {
      await setDoc(doc(db, 'kanbans', kanbanId, 'tasks', selectedTaskId), {form: value}, {merge: true})
    }, 600)
    const taskCopy = structuredClone(task)
    taskCopy.form = inputValues
    setTask(taskCopy)
    debouncedSave(inputValues)

    return () => {
      debouncedSave.cancel()
    }

  }, [inputValues])


  const handleFormSubmit = (e) => {
    e.preventDefault()
    dispatch(setDisplayTaskModal(false))
  }

  const handleSelectChange = (e) => {

    const newInputValues = {...inputValues}

    switch(e.target.name) {
      case 'type':
        newInputValues.type = e.target.value
        break;
      case 'lang':
        newInputValues.lang = e.target.value
        break;
      case 'tone':
        newInputValues.tone = e.target.value
        break
      case 'sections':
        const value = parseInt(e.target.value)
        if (value < inputValues.sections) {
          const newTitles = Object.values(newInputValues.titles)
          newTitles.length = value
          newInputValues.titles = {}
          newTitles.forEach((t, index) => {
            newInputValues.titles[index] = t
          })
        }
        newInputValues.sections = value
        break
      default :
        break;
    }

    setInputValues(newInputValues)
  }

  const handleInputChange = (e) => {

    const name = e.target.name
    const value = e.target.value

    const newInputValues = structuredClone(inputValues)
    switch(name) {
      case 'subject':
        newInputValues.subject = value
        break
      case 'title':
        newInputValues.title = value
        break
      default:
        break
    }
    setInputValues(newInputValues)
  }

  const handleCheckBoxChange = () => {
    const newInputValues = structuredClone(inputValues)
    newInputValues.chooseParts = !newInputValues.chooseParts
    newInputValues.titles = {}
    setInputValues(newInputValues)
  }

  const handleSwitchChange = () => {
    const newInputValues = structuredClone(inputValues)
    newInputValues.autoMix = newInputValues.autoMix ? !newInputValues.autoMix : true
    setInputValues(newInputValues)
  }

  const handleTitleChange = (value, index) => {
    const newInputValues = structuredClone(inputValues)
    newInputValues.titles[index] = value
    setInputValues(newInputValues)
  }

  const generateArticle = async () => {
    const titlesAreDefined = inputValues.chooseParts === true && Object.values(inputValues.titles).length === inputValues.sections
    const titlesAreCorrect = titlesAreDefined || inputValues.chooseParts === false
    const allowedFree = hasTokens && tasks.filter(task => task.status === 'in-progress').length === 0
    const allowed = hasBought || allowedFree

    if (inputValues.subject !== '' && titlesAreCorrect && allowed) {
      dispatch(setDisplayTaskModal(false))
      const inProgressTasks = tasks.filter(task => task.status === 'in-progress')
      const newIndex = inProgressTasks.length
      const freeAllowed = hasTokens && inProgressTasks.length === 0
      if (hasBought || freeAllowed) {
        await setDoc(doc(db, 'kanbans', kanbanId, 'tasks', task.id), { status: 'in-progress', index: newIndex }, {merge: true})
        const wraityService = new WraityService(accessToken)
        await wraityService.generateArticle(task, tasks, scanData, kanbanId)
      } else {
        toast({
          title: "Impossible de générer l'article.",
          description: "La formule Pro afin de générer des articles en simultané.",
          status: 'warning',
          duration: 6000,
          isClosable: true,
        })
      }
    } else if (!allowed) {
      // TODO : display buy modal
      dispatch(setDisplaySubscriptionModal(true))
    } else {
      toast({
        title: "Impossible de générer l'article",
        description: "Assurez vous d'avoir rempli tous les champs du formulaire",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const saveKeyword = () => {
    // handling local state keywords
    const keywordsCopy = structuredClone(keywords)
    if (!keywordsCopy.includes(newKeyword)) {
      keywordsCopy.push(newKeyword)
    } else {
      toast({
        title: "Impossible d'ajouter le mot-clé.",
        description: "Ce mot-clé est déjà renseigné.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
    setNewKeyword('')
    setKeywords(keywordsCopy)

    // save keywords in inputValues (to trigger firestore update)
    const inputValuesCopy = structuredClone(inputValues)
    inputValuesCopy.keywords = structuredClone(keywordsCopy)
    setInputValues(inputValuesCopy)
  }

  const displayKeywords = () => {
    if (keywords.length === 0) {
      return <Text fontSize='sm' color='gray' as='i'>Vous n'avez pas encore défini de mot-clé.</Text>
    } else {
      return (
      keywords.map((keyword, index) => (
        <Tag
          mr={1}
          mt={1}
          size='sm'
          key={index}
          borderRadius='full'
          variant='solid'
          colorScheme='gray'
        >
          <TagLabel>{keyword}</TagLabel>
          <TagCloseButton onClick={() => {removeKeyword(keyword)}}/>
        </Tag>
      ))
      )
    }
  }

  const removeKeyword = (keyword) => {
    let keywordsCopy = structuredClone(keywords)
    keywordsCopy = keywordsCopy.filter(k => k !== keyword)
    setKeywords(keywordsCopy)

    // save keywords in inputValues (to trigger firestore update)
    const inputValuesCopy = structuredClone(inputValues)
    inputValuesCopy.keywords = structuredClone(keywordsCopy)
    setInputValues(inputValuesCopy)
  }

  const preventFormSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handleKeywordInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      saveKeyword()
    }
  }

  return (
    <StyledForm onSubmit={(e) => {handleFormSubmit(e)}} onKeyPress={(e) => {preventFormSubmit(e)}}>
      <Flex w='100%' h={['auto', 'calc(100% - 85px)', 'calc(100% - 85px)']}
            overflowX='auto'
            flexDirection={['column', 'row', 'row']}
            alignItems='top'
            justifyContent='space-between' >
        <Box w={['100%', '50%', '50%']} px={[0, 2, 2]}>
          <FormControl my={4} isRequired>
            <FormLabel fontSize='sm' fontWeight='semibold'>Sujet</FormLabel>
            <Input name="subject" size='sm' value={inputValues.subject} onChange={(e) => handleInputChange(e)} placeholder='Sujet à traiter...' />
          </FormControl>
          <FormControl my={4} >
            <Flex>
              <Tooltip  label={"Ce champ optionnel vous permet de renseigner un titre principal (h1) pour l'article. Autrement, l'IA en génèrera un automatiquement."}>
                <InfoIcon color='gray.300' mr={2} mb={2}/>
              </Tooltip>
              <FormLabel fontSize='sm' fontWeight='semibold'>Titre de l'article</FormLabel>
            </Flex>
            <Input name="title" size='sm' value={inputValues.title ? inputValues.title : ''} onChange={(e) => handleInputChange(e)} placeholder='Titre de votre article...' />
          </FormControl>
          <FormControl my={4} isRequired>
            <FormLabel fontSize='sm' fontWeight='semibold'>Langue de l'article</FormLabel>
            <Select size='sm' name="lang" value={inputValues.lang} onChange={(e) => {handleSelectChange(e)}} placeholder='Choisir la langue...' >
              <option value='français'>Français</option>
              <option value='anglais'>Anglais</option>
              <option value='espagnol'>Espagnol</option>
            </Select>
          </FormControl>
          <FormControl my={4} isRequired>
            <FormLabel fontSize='sm' fontWeight='semibold'>Ton de l'article</FormLabel>
            <Select name="tone" size='sm' value={inputValues.tone} onChange={(e) => {handleSelectChange(e)}} >
              <option value='amical'>😊 Amical</option>
              <option value='drôle'>😂 Drôle</option>
              <option value='motivant'>💪🏼 Motivant</option>
              <option value='persuasif'>🧠 Persuasif</option>
              <option value='prestigieux'>💎 Prestigieux</option>
              <option value='éducatif'>📚 Educatif</option>
              <option value='professionnel'>💼 Professionnel</option>
            </Select>
          </FormControl>
          <FormControl mt={4} isRequired>
            <Flex>
              <FormLabel fontSize='sm' fontWeight='semibold'>Nombre de parties dans l'article </FormLabel>
            </Flex>
            <Select size='sm' name="sections" value={inputValues.sections} onChange={(e) => {handleSelectChange(e)}} >
              {Array.from({length: 7}, (_, i) => i + 2).map(val => (
                <option key={val} value={val}>{val === 2 ? `2 (déconseillé)`: val}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl mt={1} mb={4}>
            <Checkbox isChecked={inputValues.chooseParts} size='md' onChange={handleCheckBoxChange}>Choisir les titres des parties</Checkbox>
          </FormControl>
          <FormControl my={6}>
            <Flex alignItems='center'>
              <Tooltip label={ "Intégrez des urls vers les sites scannés sur votre site. Notez que cela n'est pas systématique, des urls sont intégrées lorsque cela est pertinent."}>
                <InfoIcon color='gray.300' mr={2} mb={2}/>
              </Tooltip>
              <FormLabel mr={4} color={'black'}>Activer le maillage automatique</FormLabel>
                  <Switch colorScheme='switch' mb={2}
                          isChecked={inputValues.autoMix ? inputValues.autoMix : false}
                          onChange={handleSwitchChange}
                          isDisabled={Object.keys(scanData).length === 0 || scanData.articles?.length === 0 }
                  />
            </Flex>
          </FormControl>
        </Box>

          <Box w={['100%', '50%', '50%']} px={[0, 2, 4]} mt={2}>
            <Box my={4}>
              <Flex>
                <Tooltip  label="Vous pouvez précisez les mots-clés de l'article. Si vous ne le faites pas, l'IA en détectera automatiquement. Les mots-clés seront en gras dans l'article généré.">
                  <InfoIcon color='gray.300' mr={2} mb={2}/>
                </Tooltip>
                <FormLabel fontSize='sm' fontWeight='semibold'>Vos mots-clés</FormLabel>
              </Flex>
              <Flex mb={2} flexWrap='wrap'>
                {displayKeywords()}
              </Flex>
              <Flex>
                <Input name="newKeyword" size='sm' value={newKeyword}
                        onChange={(e) => setNewKeyword(e.target.value)} placeholder='Rentrez un mot-clé à ajouter'
                        onKeyPress={(e) => {handleKeywordInputKeyPress(e)}}
                />
                <Button size='sm' colorScheme='green' ml='2' onClick={saveKeyword}>+</Button>
              </Flex>
            </Box>
            {inputValues.chooseParts &&
              <Box mt={6}>
                <Box>
                  <Flex>
                    <Tooltip  label={"Chaque titre prendra la forme d'un titre secondaire (h2) dans l'article."}>
                      <InfoIcon color='gray.300' mr={2} mb={2}/>
                    </Tooltip>
                    <Text fontSize='sm' fontWeight='bold'>
                      Veuillez choisir les titres de vos parties
                    </Text>
                  </Flex>
                </Box>
                <Box h='calc(100% - 20px)' overflowY='auto'>
                  {Array.from({length: inputValues.sections}).map((val, index) => (
                    <FormControl my={3} key={val}>
                      <Input size='sm' placeholder={`Titre de la partie ${index + 1}`}
                            value={inputValues.titles[index] ? inputValues.titles[index] : ''}
                            onChange={(e) => {handleTitleChange(e.target.value, index)}}
                      />
                    </FormControl>
                  ))}
                </Box>
              </Box>
            }
          </Box>

      </Flex>
      <Box h='75px' my={2} alignItems='center' flexDirection={'column'}>
        <Flex w='100%' justifyContent='space-between' flexWrap='wrap'>
          <Flex>
            <Button size='sm' bg='brand.primary' color='white' _hover={{ background: "brand.secondary"}} mr={4} onClick={generateArticle}>Générer l'article</Button>
            <Button size='sm' colorScheme='gray' type='submit' mr={4}>Enregistrer</Button>
          </Flex>
        </Flex>
        {/* <Text fontSize='sm' color='gray' fontWeight='bold' my={2}>
          Estimation du nombre de mots: {inputValues.sections * 3 * 45 + 30 + inputValues.sections * 5} à {inputValues.sections * 3 * 70 + 30 + inputValues.sections * 5}
        </Text> */}
      </Box>
    </StyledForm>
  )
}

export default TaskForm
