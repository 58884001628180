import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Text, Box, Link, UnorderedList, ListItem, Divider, Button, Flex, Tooltip, InputGroup, Input, InputLeftElement } from "@chakra-ui/react"
import { ExternalLinkIcon, MinusIcon, PlusSquareIcon, SearchIcon } from "@chakra-ui/icons"
import Modal from "../Modal"
import { setScanData } from "../../redux/actions/kanbanActions"
import { setDisplayScan } from '../../redux/actions/modalsActions'
import { getDoc, setDoc, doc } from 'firebase/firestore'
import { db } from '../../config/firebase-config'
import { useSelector } from "react-redux"


function Scan() {

  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [articles, setArticles] = useState([])
  const [ignoredArticles, setIgnoredArticles] = useState([])
  const [search, setSearch] = useState('')

  const { kanbanId, domain } = useSelector(state => state.kanban)

  const closeModal = () => {
    dispatch(setDisplayScan(false))
  }

  useEffect(() => {
    const getScanData = async (id) => {
      const scanDoc = await getDoc(doc(db, 'scans', id))
      const scanData = await scanDoc.data()
      setArticles(scanData.articles)
      const ignored = scanData.ignoredArticles ? scanData.ignoredArticles : []
      setIgnoredArticles(ignored)
      dispatch(setScanData({articles: scanData.articles, ignoredArticles: ignored }))
      setIsLoading(false)
    }
    if (kanbanId) getScanData(kanbanId)
  }, [kanbanId, dispatch])

  const ignoreArticle = async (article) => {
    const ignoredArticlesCopy = structuredClone(ignoredArticles)
    ignoredArticlesCopy.push(article)
    setIgnoredArticles(ignoredArticlesCopy)
    await setDoc(doc(db, 'scans', kanbanId), {ignoredArticles: ignoredArticlesCopy}, {merge: true})
  }

  const reconsiderArticle = async (article) => {
    let ignoredArticlesCopy = structuredClone(ignoredArticles)
    ignoredArticlesCopy = ignoredArticlesCopy.filter(a => a.url !== article.url)
    setIgnoredArticles(ignoredArticlesCopy)
    await setDoc(doc(db, 'scans', kanbanId), {ignoredArticles: ignoredArticlesCopy}, {merge: true})
  }

  return(
    <Modal closeModal={closeModal} isLoading={isLoading }>
      <Box h='100%'>
        <Box h={['230px', '200px', '120px']} bgColor='white'>
          <Text fontSize='2xl'>
            {`Liste des articles scannés (${articles.length}) sur : `}
            <Text as='span' fontWeight='bold'>
              {domain}
            </Text>
          </Text>
          { domain !== "" &&
            <Text my={2}>
              Les titres ci-dessous correpondent au <Text as='span' fontWeight='bold'>premier h1</Text> trouvé sur chaque page.
            </Text>
          }
          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon color='gray.300' mb={2}/>
            </InputLeftElement>
            <Input size='sm' w='600px' placeholder='Recherchez un article' onChange={(e) => {setSearch(e.target.value)}} />
          </InputGroup>
          <Divider mt={2}/>
        </Box>
        <Box h={['calc(100% -230px)', 'calc(100% - 200px)', 'calc(100% - 120px)']} overflowY='auto'>

          <UnorderedList>
            {articles.filter(a => a.title.toLowerCase().includes(search.toLocaleLowerCase())).map((article, index) => (
              <ListItem my={4} key={index}>
                <Flex w='100%' alignItems='center' justifyContent='space-between' px={8}>
                  <Link href={article.url} isExternal
                        opacity={ignoredArticles.map(a => a.url).includes(article.url) ? 0.3 : 1}
                  >
                    {article.title} <ExternalLinkIcon mx='2px' />
                  </Link>
                  {ignoredArticles.map(a => a.url).includes(article.url) ?
                  <Tooltip label='Reprendre en compte cet article pour le générateur de sujets ou le maillage interne.' >
                    <Button size='sm' onClick={() => {reconsiderArticle(article)}}>
                      <PlusSquareIcon />
                    </Button>
                  </Tooltip>
                  :
                  <Tooltip label='Ne pas prendre en compte cet article pour le générateur de sujets ou le maillage interne.' >
                    <Button size='sm' onClick={() => {ignoreArticle(article)}}>
                      <MinusIcon />
                    </Button>
                  </Tooltip>
                  }




                </Flex>
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
      </Box>
    </Modal>
  )
}

export default Scan
