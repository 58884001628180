import { Text, UnorderedList, ListItem, Button, Flex, Box } from '@chakra-ui/react'
import { RepeatIcon} from '@chakra-ui/icons'
import { v4 as uuidv4 } from 'uuid'
import {useSelector } from 'react-redux'
import { useState } from 'react'
import { db } from '../../config/firebase-config'
import { doc, setDoc } from 'firebase/firestore'


function IdeasResults({results, getOnlineArticles}) {

  const { tasks, kanbanId } = useSelector(state => state.kanban)

  const [selectedIdeas, setSelectedIdeas] = useState([])

  const handleClick = (subject) => {
    const selectedIdeasCopy = structuredClone(selectedIdeas)
    selectedIdeasCopy.push(subject)
    setSelectedIdeas(selectedIdeasCopy)
    createTask(subject)
  }

  const createTask = async (subject) => {
    const tasksCopy = structuredClone(tasks)
    const tasksToDo = tasksCopy.filter(task => task.status === 'to-do')

    const newId = uuidv4()
    const newTask = {
      article: "",
      form: {
        subject: subject,
        lang: 'français',
        tokens: 450,
        tone: 'amical',
        sections: 3,
        chooseParts: false,
        titles: {},
        keywords: []
      },
      id: newId,
      status:'to-do',
      title: subject,
      index: tasksToDo.length
    }

    await setDoc(doc(db, 'kanbans', kanbanId, 'tasks', newId), newTask)
  }

  const handleRefreshIdeas = async () => {
    getOnlineArticles()
  }

  return (
    <Box h='100%' overflowY='hidden'>
      <Flex backgroundColor='white' h={["120px", "90px", "90px"]} width={'100%'} mb={8} justifyContent={'space-between'} alignItems={'center'} flexWrap='wrap'>
        <Text fontSize='2xl' fontWeight={'bold'}>Voici des sujets que vous pourriez traiter :</Text>
        <Button size={'sm'} colorScheme='switch' my={8} onClick={() => {handleRefreshIdeas()}}>
          <RepeatIcon mr={4} />
          Générer de nouvelles idées
        </Button>
      </Flex>
      <Box overflowY='auto' h={['calc(100% - 120px)', 'calc(100% - 90px)', 'calc(100% - 90px)']} mt={[8, 0]} pb={12}>
        <UnorderedList>
          {results.map((result, index) => (
            <ListItem my={2} key={index}>
              <Flex width={'100%'} flexDirection='column' my={8} >
                <Text fontSize="xl">
                  {result}
                </Text>
                <Button my={3} w='150px' isDisabled={selectedIdeas.includes(result)} onClick={() => {handleClick(result)}} size="sm">✚ Nouvel article</Button>
              </Flex>
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
    </Box >
  )
}

export default IdeasResults
