import { useEffect, useRef, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { db } from '../config/firebase-config'
import { doc, onSnapshot } from 'firebase/firestore'
import { setUserUsage, setHasTokens, setHasBought, setCustomerId } from '../redux/actions/userActions'

function useUpdateUsageAndHasBought() {

  const dispatch = useDispatch()

  const { uid } = useSelector(state => state.user)

  const unsubscribe = useRef(() => {})

  const customCheckHasBought = useCallback(() => {
    // check if we passed May the 30th
    const today = new Date()
    const lastMay = new Date(today.getFullYear(), 4, 30)
    const passedDeadline = today > lastMay

    const whiteList = [
      "7R25b2fLpyMFMcHsa072GnPyaD83",
      "UJUTLRs5eUPxnTDtnrAk8tuL7Dg2",
      "erVyItidd5cm634liS0KSsqL15A2",
      "xvMuKIb2VrVrAj5F69uW1LCK7P43",
    ]

    return !passedDeadline && whiteList.includes(uid)
  }, [uid])

  useEffect(() => {
    if (uid && uid !== '') {
      unsubscribe.current()
      unsubscribe.current = onSnapshot(doc(db, 'users', uid), (doc) => {
        if (doc.exists()) {
          const userData = doc.data()
          const correctUsage = userData.totalUsage ? userData.totalUsage : 0
          dispatch(setUserUsage(correctUsage))

          const hasTokens = 2000 - (correctUsage * 0.75) > 0
          dispatch(setHasTokens(hasTokens))

          const hasBought = userData.hasBought ? userData.hasBought : customCheckHasBought()
          dispatch(setHasBought(hasBought))

          const customerId = userData.stripeCustomerId ? userData.stripeCustomerId : null
          dispatch(setCustomerId(customerId))
        }
      });
    }
  }, [uid, dispatch, customCheckHasBought])
}

export default useUpdateUsageAndHasBought
