import { useState, useEffect, useRef } from "react";
import WraityService from "../../../services/wraity";
import {Textarea, Button, Flex, Spinner, Box } from "@chakra-ui/react"
import { RepeatIcon, CopyIcon } from "@chakra-ui/icons";
import { useSelector, useDispatch } from 'react-redux'
import { setDisplaySubscriptionModal, setDisplayTaskModal } from '../../../redux/actions/modalsActions'
import { db } from "../../../config/firebase-config";
import { doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";


const actions = [
    { key: 'regenerate' , title: 'Recommencer', icon: <RepeatIcon mr={1}/>},
    { key: 'copy', title: 'Copier', icon: <CopyIcon mr={1}/>},
]

const Post = ({ task, post, updateProcessingPosts, processingPosts, setProcessingPosts }) => {

  const dispatch = useDispatch()

  const [value, setValue] = useState(post.post)

  const { accessToken, hasTokens, hasBought } = useSelector((state) => state.user)

  const unsubscribe = useRef()

  useEffect(() => {
    unsubscribe.current = onSnapshot(doc(db, "social-networks-posts", task.id), (doc) => {
      const data = doc.data()
        if (data !== undefined ) {
          const currentPost = data.posts?.find(p => p.socialNetwork === post.socialNetwork)
          setValue(currentPost?.post)
          setProcessingPosts(data.processingPosts)
        }
    })

    return () => {
      unsubscribe.current()
    }
  }, [post, task, setProcessingPosts])

  const regeneratePost = async (socialNetworkName) => {
    const allowed = hasTokens || hasBought
    if (allowed) {
      const gptSettings = {
        context: 'socialNetworksPost',
        subject: task.form.subject,
        lang: task.form.lang,
        action: 'generate',
        socialNetwork: socialNetworkName,
        tone: task.form.tone,
        text: task.article
      }

      try {
        await updateProcessingPosts(socialNetworkName)
        const wraityService = new WraityService(accessToken)
        await wraityService.generateSocial(gptSettings, task.id)
      } catch(error){
        console.log(error)
      }
    } else {
      dispatch(setDisplayTaskModal(false))
      // TODO : display buy modal
      dispatch(setDisplaySubscriptionModal(true))
    }
  }

  const handleActionClick = async (action) => {
    const allowed = hasTokens || hasBought
      if (allowed || action === 'copy') {
        switch (action) {
          case 'copy':
            navigator.clipboard.writeText(value)
            break;
          case 'regenerate':
            await regeneratePost(post.socialNetwork)
            break;

          default:
            console.log('This action is not implemented')
        }
      } else {
        dispatch(setDisplayTaskModal(false))
        // TODO : display buy modal
        dispatch(setDisplaySubscriptionModal(true))
      }
  }

  const handleTextareaChange = (value) => {
    setValue(value)
  }

  const handleBlur = async () => {
    const postsDoc = await getDoc(doc(db, 'social-networks-posts', task.id))
    const postsData = await postsDoc.data()
    const postsCopy = structuredClone(postsData.posts)
    postsCopy.find(p => p.socialNetwork === post.socialNetwork).post = value
    await setDoc(doc(db, 'social-networks-posts', task.id), {posts: postsCopy}, {merge: true})
  }

  return (
    <Box mb={6}>
        <Textarea
          value={value}
          onChange={e => handleTextareaChange(e.target.value)}
          size='lg'
          minH={150}
          disabled={processingPosts?.includes(post.socialNetwork)}
          onBlur={handleBlur}
          />

        <Flex align='center' justify='flex-end' my={2}>
          {actions.map((action, index) => {
            return(
              <Button key={index}
                      size='sm' mx={1}
                      colorScheme={action.key === 'copy' ? 'switch' : 'gray'}
                      isDisabled={ processingPosts?.includes(post.socialNetwork) }
                      onClick={() => handleActionClick(action.key)}>
                {processingPosts?.includes(post.socialNetwork) && action.key !== 'copy' ? <Spinner mr={1} size='sm'/> : action.icon}
                <span>{action.title}</span>
              </Button>
            )
          })}
        </Flex>
    </Box>
  );
};

export default Post
