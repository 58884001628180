import WraityClient from "../clients/wraity-client"

class WraityService {

  constructor(token = "") {
    this.wraityClient = new WraityClient(token)
  }

  generateArticle = async (task, tasks, scanData, kanbanId) => {
    const settings = this.getGptSettings(task, tasks, scanData)
    const response = await this.wraityClient.generateArticle(settings, kanbanId, task.id)
    return response
  }

  generateIdeas = async (settings) => {
    const response = await this.wraityClient.generateIdeas(settings)
    const data = await response.json()
    return data.result
  }

  scanWebsite = async (domain, kanbanId) => {
    const response = await this.wraityClient.scanWebsite(domain, kanbanId)
    const data = await response.json()
    return data
  }

  generateMetaData = async (settings, kanbanId, taskId) => {
    const data = await this.wraityClient.generateMetaData(settings, kanbanId, taskId)
    return data
  }

  generateSocial = async (settings, taskId) => {
    const response = await this.wraityClient.generateSocial(settings, taskId)
    return response
  }

  handleArticleAction = async (task, tasks, scanData, kanbanId) => {
    const settings = this.getGptSettings(task, tasks, scanData)
    const response = await this.wraityClient.handleArticleAction(settings, kanbanId, task.id)
    return response
  }

  deleteKanbanTasks = async (kanbanId) => {
    const response = await this.wraityClient.deleteKanbanTasks(kanbanId)
    return response
  }

  getGptSettings = (task, tasks, scanData) => {
    let gptSettings
    if (task.context === "existingArticle") {
      gptSettings = {
        context: 'existingArticle',
        tokens: task.form.tokens,
        lang: task.form.lang,
        subject: task.form.subject,
        url: task.url,
      }
    } else {
      gptSettings = {
        context: task.context ? task.context : 'article',
        action: task.action ? task.action : '',
        text : task.article ? task.article : '',
        subject: task.form.subject,
        lang: task.form.lang,
        tone: task.form.tone,
        sections : task.form.sections,
        titles: task.form.titles,
        subjects: [],
        title: task.form.title ? task.form.title : '',
        keywords: task.form.keywords ? task.form.keywords : []
      }
      if (task.form.autoMix) {
        const ignoredUrls = scanData.ignoredArticles.map(a => a.url)
        const onlineArticles = scanData.articles.filter(a => !ignoredUrls.includes(a.url))
        const limitations = {2: 30, 3: 25, 4: 20, 5: 15, 6: 15, 7: 10, 8: 10 }
        const limit = limitations[task.form.sections]
        if (onlineArticles.length > limit) {
          const shuffledArticles = onlineArticles.sort((a, b) => 0.5 - Math.random());
          gptSettings.articles = shuffledArticles.slice(0, limit)
        } else {
          gptSettings.articles = onlineArticles
        }
      }
    }
    return gptSettings
  }
}

export default WraityService
