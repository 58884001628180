import { Box } from "@chakra-ui/react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import ArticleActions from './ArticleActions.jsx';
import { db } from "../../../config/firebase-config.js";
import { doc, setDoc } from "firebase/firestore";

const EditorContainer = styled(Box)`
  height: 100%;
  color: black !important;

  p {
    font-size: 16px !important;
    margin: 5px 0;
  }

  ul, ol {
    font-size: 16px !important;
    margin: 5px 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 10px 0;
    font-weight: bold;
  }

  .quill {
    height: 100%;
  }

  .ql-toolbar {
    height: 50px;
  }

  .ql-container {
    height: calc(100% - 50px);
  }

  @media (max-width: 768px) {
    .ql-toolbar {
      height: 70px;
    }

    .ql-container {
      height: calc(100% - 120px);
    }
  }
`;

const StyledQuillEditor = styled(ReactQuill)`
  opacity: ${props => props.$isLoading ? 0.5 : 1};
`;

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image'],
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

const Article = ({ task }) => {
  const { kanbanId } = useSelector(state => state.kanban);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (task && task.article) {
      setValue(task.article);
      setLoading(false);
    }
  }, [task]);

  const updateArticle = async () => {
    console.log("update article");
    if (kanbanId && task.id) {
      await setDoc(doc(db, 'kanbans', kanbanId, 'tasks', task.id), { article: value }, { merge: true });
    }
  };

  const handleChange = (content) => {
    setValue(content);
  };

  return (
    <EditorContainer>
      <Box h='calc(100% - 50px)' onBlur={updateArticle}>
        <StyledQuillEditor
          theme="snow"
          modules={modules}
          formats={formats}
          value={value}
          onChange={handleChange}
          $isLoading={loading}
        />
      </Box>
      <ArticleActions task={task} value={value} setValue={setValue} />
    </EditorContainer>
  );
};

export default Article;
