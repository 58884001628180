import produce from 'immer'

const defaultState = {
  displayTaskModal: false,
  displayIdeasModal: false,
  displaySubscriptionModal: false,
  displayExistingArticleModal: false,
  displayScanModal: false,
  displayTutorial: false,
  displayApiModal: false
}

function modalsReducer(state = defaultState, action) {

  switch(action.type) {
    case 'setDisplayTaskModal':
      return produce(state, (draft) => {
        draft.displayTaskModal = action.payload
      })

    case 'setDisplayIdeasModal':
      return produce(state, (draft) => {
        draft.displayIdeasModal = action.payload
      })

    case 'setDisplaySubscriptionModal':
      return produce(state, (draft) => {
        draft.displaySubscriptionModal = action.payload
      })

    case 'setDisplayExistingArticleModal':
      return produce(state, (draft) => {
        draft.displayExistingArticleModal = action.payload
      })

    case 'setDisplayTutorial':
      return produce(state, (draft) => {
        draft.displayTutorial = action.payload
      })

    case 'setDisplayScan':
      return produce(state, (draft) => {
        draft.displayScanModal = action.payload
      })

    case 'setDisplayApiModal':
      return produce(state, (draft) => {
        draft.displayApiModal = action.payload
      })

    default:
      return state
  }


}

export default modalsReducer
