import { Flex, Box, Button, Tag, Icon, Text} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import Logo from '../../assets/logo.png'
import { auth } from '../../config/firebase-config'
import { FiLogOut } from 'react-icons/fi'
import { signOut } from 'firebase/auth'
import { useState, useEffect } from 'react'
import { setDisplaySubscriptionModal, setDisplayApiModal } from '../../redux/actions/modalsActions'
import { useNavigate } from 'react-router-dom'

function Navbar() {


  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { uid, email, hasBought } = useSelector(state => state.user)


  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;




  const handleBuyClick = () => {
    // TODO : display buy modal
    dispatch(setDisplaySubscriptionModal(true))
  }

  const handleLogOut = () => {
    signOut(auth)
  }

  const openApiModal = () => {
    dispatch(setDisplayApiModal(true))
  }

  return (
    <Flex w='100vw' h='50px' backgroundColor='white' justifyContent='space-between' alignItems='center'>
      <Box w={['0px', '200px', '200px']} onClick={() => navigate("/app/dashboard")}>
        <img src={Logo} alt="copywraiter logo" />
      </Box>
      { !isMobile && uid !== '' &&
        <Flex px={8} alignItems='center' w={['100%', 'calc(100% - 200px)', 'calc(100% - 200px)']} justifyContent='flex-end'>

          {hasBought ?
            <Tag size='md' variant='solid' colorScheme='green' mx={1}>
              Accès illimité
            </Tag>
            :
            <Tag size='md' colorScheme='yellow' mx={1}>
              Free
            </Tag>
          }
          <Button size='sm' mx={1} colorScheme='gray' onClick={() => navigate("/app/account")}>
            {email}
          </Button>
          { !hasBought &&
            <Button size='sm' mx={1} colorScheme='switch' onClick={handleBuyClick}>
              Acheter
            </Button>
          }
          {hasBought &&
            <Button size='sm' mx={1} colorScheme='switch' onClick={openApiModal}>
              Gérer ma clef API
            </Button>
          }
          <Button size='sm' onClick={handleLogOut} mx={1}>
            <Icon as={FiLogOut} />
          </Button>
        </Flex>
      }
      {isMobile &&
        <Flex px={4} alignItems='center' w='100%' justifyContent='space-between' >
          <Flex>
            <Text>{email}</Text>
            {hasBought ?
            <Tag size='md' variant='solid' colorScheme='messenger' mx={1}>
              Pro
            </Tag> :
            <Tag size='md' colorScheme='yellow' mx={1}>
              Free
            </Tag>
          }
          </Flex>
          { !hasBought &&
            <Button size='xs' mx={1} onClick={handleBuyClick}>
              Acheter
            </Button>
          }
          <Button size='xs' onClick={handleLogOut} mx={1}>
            <Icon as={FiLogOut} />
          </Button>
        </Flex>
      }
    </Flex>
  )
}

export default Navbar
