import styled from "styled-components"

const Container = styled.div`
  height: 100%;
  width: 100vw;
  z-index: 2;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
`

function Modal({children}) {
  return (
    <Container>
        {children}
    </Container>
  )
}

export default Modal
