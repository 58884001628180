import { auth } from "../config/firebase-config";
import store from '../redux/index';
import { updateUserToken } from "../redux/actions/userActions";
import { signOut } from "firebase/auth";

class WraityClient {
  constructor(token) {
    this.apiBaseUrl = process.env.NODE_ENV !== 'production' ? "http://localhost:4200/api" : process.env.REACT_APP_WRAITY_URL;
    this.token = token;
  }

  refreshAuth = async () => {
    if (!auth.currentUser) {
      console.error('No current user found. Signing out.');
      signOut(auth);
      throw new Error('No current user');
    }
    try {
      console.log('Attempting to refresh token...');
      const newToken = await auth.currentUser.getIdToken(true);
      this.token = newToken;
      store.dispatch(updateUserToken(newToken));
    } catch (error) {
      console.error('Failed to refresh auth token:', error);
      signOut(auth);
      throw error;
    }
  }

  handleError = async (response, attempts) => {
    if (response && response.status === 401 && attempts === 1) {
      await this.refreshAuth();
    }

    if (attempts >= 2) {
      throw new Error('Failed to generate article');
    }
  }

  createOptions = (method, body) => {
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
      },
    };
    if (body) {
      options.body = JSON.stringify(body);
    }
    return options;
  }

  makeRequest = async (url, method, body, attempts = 0) => {
    try {
      const options = this.createOptions(method, body);
      const response = await fetch(url, options);
      if (response.ok) {
        return response;
      } else {
        throw response;
      }
    } catch (error) {
      console.error('Request error:', error);
      await this.handleError(error, attempts);
      if (attempts < 2) {
        return this.makeRequest(url, method, body, attempts + 1);
      } else {
        throw error;
      }
    }
  }

  generateArticle = async (settings, kanbanId, taskId) => {
    const url = this.apiBaseUrl + '/wraity/article';
    return this.makeRequest(url, 'POST', { settings, kanbanId, taskId });
  }

  handleArticleAction = async (settings, kanbanId, taskId) => {
    const url = this.apiBaseUrl + '/wraity/actions';
    return this.makeRequest(url, 'POST', { settings, kanbanId, taskId });
  }

  generateIdeas = async (settings) => {
    const url = this.apiBaseUrl + '/wraity/ideas';
    return this.makeRequest(url, 'POST', { settings });
  }

  generateMetaData = async (settings, kanbanId, taskId) => {
    const url = this.apiBaseUrl + '/wraity/metadata';
    return this.makeRequest(url, 'POST', { settings, taskId, kanbanId });
  }

  generateSocial = async (settings, taskId) => {
    const url = this.apiBaseUrl + '/wraity/social';
    return this.makeRequest(url, 'POST', { settings, taskId });
  }

  createStripeSession = async (billing) => {
    const url = this.apiBaseUrl + "/stripe/sessions/new";
    return this.makeRequest(url, 'POST', { billing });
  }

  retrieveStripeSession = async (sessionId) => {
    const url = this.apiBaseUrl + `/stripe/sessions/${sessionId}`;
    return this.makeRequest(url, 'GET');
  }

  scanWebsite = async (domain, kanbanId) => {
    const url = this.apiBaseUrl + `/blogscanner`;
    return this.makeRequest(url, 'POST', { domain, kanbanId });
  }

  deleteKanbanTasks = async (kanbanId) => {
    const url = this.apiBaseUrl + `/firestore/delete-kanban-tasks`;
    return this.makeRequest(url, 'POST', { kanbanId });
  }
}

export default WraityClient;
