import { Flex, Heading, Icon, Button, Spinner, Text, Box } from '@chakra-ui/react'
import { AiFillLinkedin, AiFillTwitterSquare, AiFillMail, AiFillInstagram } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import WraityService from '../../../services/wraity'
import styled from 'styled-components'
import _ from 'lodash'
import { useState, useEffect } from 'react'
import { db } from '../../../config/firebase-config'
import { getDoc, setDoc, doc, onSnapshot } from 'firebase/firestore'
import Post from './Post'
import { setDisplayTaskModal, setDisplaySubscriptionModal } from '../../../redux/actions/modalsActions'

const ActionsContainer = styled.div`
  height: ${ props => props.$actions ? '100px' : '0px' };
  border-bottom: solid 1px #E1E1E1;
  @media (max-width: 768px) {
    height: 150px;
    margin-top: 50px;
  }
`

const PostsContainer = styled.div`
  height: ${props => props.$actions ? 'calc(100% - 100px)' : '100%'};
  padding: 20px 0;
  @media (max-width: 768px) {
    height: ${props => props.$actions ? 'calc(100% - 150px)' : '100%'};
    padding: 10px;
  }
`

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

function SocialNetworksSection({task}) {

  const socialNetworks = [
    {name: 'linkedin', icon: AiFillLinkedin},
    {name: 'twitter', icon: AiFillTwitterSquare},
    {name: 'mail', icon: AiFillMail},
    {name: 'instagram', icon: AiFillInstagram},
  ]

  const dispatch = useDispatch()

  const { accessToken, hasTokens, hasBought } = useSelector((state) => state.user)

  const [posts, setPosts] = useState([])
  const [processingPosts, setProcessingPosts] = useState([])
  const [socialButtons, setSocialButtons] = useState(socialNetworks)
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    const getPosts = async () => {
      const postsDoc = await getDoc(doc(db, 'social-networks-posts', task.id))
      if (postsDoc.exists()) {
        const postsData = postsDoc.data()
        setPosts(postsData.posts)
        setProcessingPosts(postsData.processingPosts)
      } else {
        await setDoc(doc(db, 'social-networks-posts', task.id), {posts: [], processingPosts: []})
      }
      setIsLoading(false)
  }

    getPosts()

    const unsubscribe = onSnapshot(doc(db, "social-networks-posts", task.id), (doc) => {
    const data = doc.data()
      if (data !== undefined) {
        setPosts(data.posts)
        setProcessingPosts(data.processingPosts)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [task])

  useEffect(() => {
    const ignoredSocials = posts ? posts.map(p => p.socialNetwork) : []
    const newSocialButtons = socialButtons.filter(s => !ignoredSocials.includes(s.name))
    setSocialButtons(newSocialButtons)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts])

  const updateProcessingPosts = async (socialNetworkName) => {
    const processingPostsCopy = structuredClone(processingPosts)
    processingPostsCopy.push(socialNetworkName)
    await setDoc(doc(db, 'social-networks-posts', task.id), {processingPosts: processingPostsCopy},{merge: true})
  }

  const createSocialNetworkPost = async (socialNetworkName) => {
    if (hasTokens || hasBought) {
      const gptSettings = {
        context: 'socialNetworksPost',
        subject: task.form.subject,
        lang: task.form.lang,
        action: 'generate',
        socialNetwork: socialNetworkName,
        tone: task.form.tone,
        text: task.article
      }

      try {
        await updateProcessingPosts(socialNetworkName)
        const wraityService = new WraityService(accessToken)
        await wraityService.generateSocial(gptSettings, task.id)
      } catch(error){
        console.log(error)
      }
    } else {
      dispatch(setDisplayTaskModal(false))
      // TODO : display buy modal
      dispatch(setDisplaySubscriptionModal(true))
    }
  }

  if (isLoading) {
    return (
      <>
        <SpinnerContainer>
          <Spinner size='lg' color='grey'/>
        </SpinnerContainer>
      </>
    )
  } else {
    return (
      <Box>
        <ActionsContainer $actions={socialButtons.length > 0}>
          { socialButtons.length > 0 && <Heading size='xs' my={4}>Cliquez sur les boutons ci-dessous pour générer des publications :</Heading>}
            <Flex flexWrap={'wrap'}>
              {socialButtons.map((socialButton, index) => (
                <Button mx={1} key= {index}
                            bg='brand.primary' color='white' _hover={{ background: "brand.secondary"}}
                            variant='outline'
                            size='md'
                            onClick={() => {createSocialNetworkPost(socialButton.name)}}
                            mt={[2, 0]}
                            isLoading={processingPosts?.includes(socialButton.name)}
                            loadingText={_.capitalize(socialButton.name)}
                            >
                              <Icon mr={2} fontSize='18' as={socialButton.icon}/>
                              <span>{_.capitalize(socialButton.name)}</span>
                </Button>
              ))}
            </Flex>
        </ActionsContainer>

        <PostsContainer $actions={socialButtons.length > 0}>
          {posts?.map((post, index) => (
            <Box key={index}>
              <Flex alignItems={'center'} mb={1}>
                <Icon color='brand.primary'
                      mr={1}
                      fontSize='18'
                      as={socialNetworks.find(n => n.name === post.socialNetwork).icon}
                      boxSize={8}
                />
                <Text fontWeight="bold">{_.capitalize(post.socialNetwork)}</Text>
              </Flex>
              <Post task={task}
                    post={post}
                    createSocialNetworkPost={createSocialNetworkPost}
                    updateProcessingPosts={updateProcessingPosts}
                    setProcessingPosts={setProcessingPosts}
                    processingPosts={processingPosts}
              />
            </Box>
          ))}
        </PostsContainer>
      </Box>

    )
  }
}

export default SocialNetworksSection
