import produce from 'immer'

const defaultState = {
  kanbanId: '',
  selectedTaskId: '',
  tasks: [],
  columns: [],
  scanData: {articles: [], ignoredArticles: [] },
  waitingTasks : [],
  isProcessing: false,
  domain: null,
  lastScan: null
}

function kanbanReducer(state = defaultState, action) {

  switch(action.type) {

    case 'resetKanban':
      return produce(state, (draft) => {
        draft.kanbanId = ''
        draft.selectedTaskId = ''
        draft.tasks = []
        draft.columns = []
        draft.scanData = { articles: [], ignoredArticles: [] }
        draft.waitingTasks = []
        draft.isProcessing = false
        draft.domain = null
      })

    case 'setKanbanId':
      return produce(state, (draft) => {
        draft.kanbanId = action.payload
      })

    case 'setTasks':
      return produce(state, (draft) => {
        draft.tasks = action.payload
      })

    case 'setSelectedTaskId':
      return produce(state, (draft) => {
        draft.selectedTaskId = action.payload
      })

    case 'setKanbanColumns':
      return produce(state, (draft) => {
        draft.columns = action.payload
      })

    case 'setIsScanning':
      return produce(state, (draft) => {
        draft.isScanning = action.payload
      })

    case 'setScanData':
      return produce(state, (draft) => {
        draft.scanData = action.payload
      })

    case 'setDomain':
      return produce(state, (draft) => {
        draft.domain = action.payload
      })

    case 'setLastScan':
      return produce(state, (draft) => {
        draft.lastScan = action.payload
      })

    default:
      return state
  }
}

export default kanbanReducer
