import Modal from '../Modal'
import { Text, Box } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setDisplaySubscriptionModal } from '../../redux/actions/modalsActions'
import Confetti from 'react-confetti'
import PricingTable from './PricingTable'
import OfferAlert from './OfferAlert'

const today = new Date()
const lastMay = new Date(today.getFullYear(), 4, 30)
const passedDeadline = today > lastMay

function Subscription() {

  const dispatch = useDispatch()

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;

  const closeModal = () => {
    dispatch(setDisplaySubscriptionModal(false))
  }

  return (
    <>
      <Confetti />
      <Modal closeModal={closeModal} animated={true}>
        <Box h='100%' w='100%'>
          <Text mb={8} fontSize='2xl' fontWeight={'bold'} textAlign='center' >
            Merci d'utiliser CopyWraiter ❤️
          </Text>
          {!passedDeadline && <OfferAlert isMobile={isMobile} />}
          <PricingTable isMobile={isMobile} />
          {isMobile && <Box h='20px'></Box>}
        </Box>
      </Modal>
    </>
  )

}

export default Subscription
