import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../config/firebase-config'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { updateUser, logOutUser } from '../../redux/actions/userActions';
import Subscription from '../Subscription';
import useUpdateUsageAndHasBought from '../../hooks/useUpdateUsageAndHasBought'
import ApiModal from '../ApiModal';


// const AutoBanner = styled.div`
//   background-image: linear-gradient(to right, var(--chakra-colors-cyan-700), var(--chakra-colors-purple-500));
//   position: relative;
//   overflow: hidden;
//   height: 30px;
//   width: 100vw;
//   text-align: center;

//   p {
//     width: 100vw;
//     position: absolute;
//     color: white;
//     font-weight: semi-bold;
//   }

//   span {
//     font-weight: bold;
//     margin-left: 5px;
//   }
// }
// `

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow-x: hidden;

  @media (max-width: 768px) {
    height: 100dvh;
  }

`

const StyledBox = styled.div`
  height: calc(100%);
`

function Layout({mode, children}) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { uid } = useSelector(state => state.user)
  const { displaySubscriptionModal, displayApiModal } = useSelector(state => state.modals)

  useUpdateUsageAndHasBought()

  onAuthStateChanged(auth, async (currentUser) => {
    if (currentUser) {
      if (currentUser.uid !== uid) {
        dispatch(updateUser(currentUser))
      }
    } else {
      dispatch(logOutUser())
      navigate('/login')
    }
  })

  // const updateShowTutorial = useCallback(async () => {
  //   const userDoc = await getDoc(doc(db, 'users', uid))
  //   const userData = await userDoc.data()
  //   if (userData && userData.showTutorial === undefined) {
  //     await setDoc(doc(db, 'users', uid), {showTutorial: true}, {merge: true})
  //     dispatch(setDisplayTutorial(true))
  //   } else if (userData && userData.showTutorial !== undefined) {
  //     dispatch(setDisplayTutorial(userData.showTutorial))
  //   }
  // }, [dispatch, uid])

  // useEffect(() => {
  //   if (uid && uid !== '') {
  //     updateShowTutorial()
  //   }
  // }, [uid, updateShowTutorial])

  return (
    <Container>
      {/* <AutoBanner>
        <p>
          Forte fréquentation sur CopyWraiter, des désagréments éventuels sont à prévoir dans les prochaines heures. Si besoin, envoyez un mail à :
        <span>
          paul@copywraiter.io 😉
        </span>
        </p>

      </AutoBanner> */}
      <StyledBox>
        <Outlet />
      </StyledBox>
      {displaySubscriptionModal && <Subscription />}
      {displayApiModal && <ApiModal />}
    </Container>
  )
}

export default Layout
