import * as React from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function PricingTable() {

    const { uid } = useSelector(state => state.user)

    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      }
      }, [])

      return (
        React.createElement("stripe-pricing-table", {
        "pricing-table-id": "prctbl_1N4S2nAbFSd3nCIpgE23XYQq",
        "publishable-key": "pk_live_51Myw3OAbFSd3nCIpunuC5ttN8h7HrljJZ4DaWdPt7VVBEqylKn7KTucAaNEmPCZRpDkyzqw6l2uhLLTMFTT7g90K00espgJp4C",
        "client-reference-id": uid,
      })
      )
}

export default PricingTable
