import { Alert, AlertIcon, Text, Flex } from "@chakra-ui/react"


function OfferAlert({ isMobile }) {
  return (
    <Alert status='info' my={4} borderRadius={16} w={isMobile ? '100%' : '70%'} mx='auto'>
      <AlertIcon />
      {isMobile ?
        <Text>
          20€ de réduction sur le prix d'achat avec le code CW2
        </Text>
        :
        <Flex>
          <Text>
            20€ de réduction avec le code
          </Text>
          <Text ml={2} fontWeight='bold'>
            CW2
          </Text>
          <Text ml={2}>
            jusqu'au 30 mai 2024
          </Text>
        </Flex>
      }
    </Alert>
  )
}

export default OfferAlert
