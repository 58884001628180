import styled from 'styled-components'
import { Outlet } from 'react-router-dom'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  backgound-color: red;

  @media (max-width: 768px) {
    height: 100dvh;
  }

`

function SubLayout({mode, children}) {

  return (
    <Container>
      <Outlet />
    </Container>
  )
}

export default SubLayout
