import { useState, useEffect } from 'react';
import Modal from '../Modal';
import { setDisplayApiModal } from '../../redux/actions/modalsActions';
import { useDispatch } from 'react-redux';
import { db } from '../../config/firebase-config';
import { doc, getDoc, setDoc, updateDoc, } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { Input, IconButton, Box, Flex, Text, useToast } from '@chakra-ui/react';
import { DeleteIcon, CheckIcon } from '@chakra-ui/icons'

export default function ApiModal() {

  const toast = useToast()
  const dispatch = useDispatch()
  const { uid } = useSelector(state => state.user)

  const [isLoading, setIsLoading] = useState(false)
  const [key, setKey] = useState(null)

  const closeModal = () => {
    dispatch(setDisplayApiModal(false))
  }

  useEffect(() => {
    const getApiKey = async () => {
      const keyDoc = await getDoc(doc(db, 'openai-settings', uid))
      const data = await keyDoc.data()
      if (data) {
        setKey(data.key)
      } else {
        setKey('')
        await setDoc(doc(db, 'openai-settings', uid), {
          key: "",
          model: "gpt-3.5-turbo"
        })
      }
    }

    getApiKey()
  }, [uid])

  const saveApiKey = async () => {
    setIsLoading(true)
    if (key) {
      await updateDoc(doc(db, 'openai-settings', uid), {key: key})
    }
    toast({
      title: "Clef API enregistrée",
      status: "success",
      duration: 5000,
      isClosable: true,
    })
    setIsLoading(false)
  }

  const eraseApiKey = async () => {
    setIsLoading(true)
    await setDoc(doc(db, 'openai-settings', uid), {key: ''})
    setKey('')
    setIsLoading(false)
  }

  return (
    <Modal
      loadingText={""}
      isLoading={isLoading}
      closeModal={closeModal}
      animated={true}
      size="sm"
    >
      <Box w={'90%'}>
        <h1>Renseignez votre clef API OpenAI : </h1>
        <Flex alignItems='center' flexWrap='wrap'>
          <Input w={['100%', '250px', '300px']} value={key} onChange={(e) => {setKey(e.target.value)}} mr={2} type="password"/>
          <Flex mt={2} alignItems='center'>
            <IconButton onClick={saveApiKey} icon={<CheckIcon />} mr={2} colorScheme='green' />
            <IconButton onClick={eraseApiKey} icon={<DeleteIcon />} colorScheme='red' />
          </Flex>
        </Flex>
        <Text mt={4} color="gray.500">
          <span style={{fontWeight: "bold"}}>Vous n'avez pas de clef API ?</span> Cliquez sur le lien ci-dessous pour consulter notre tutoriel et savoir comment l'obtenir.
        </Text>
        <a href="https://www.loom.com/share/276efc10375a4b83980d70c9f5345262?sid=03c340a9-8af9-4fbc-a7c8-4096f5067597" target="_blank" style={{color: "blue", marginTop: '50px'}} rel="noreferrer">
          Voir le tutoriel
        </a>

      </Box>
    </Modal>
  )
}
