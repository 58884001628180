import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WraityService from '../../services/wraity';
import IdeasResults from './IdeasResults'
import IdeasForm from './IdeasForm';
import Modal from '../Modal';
import { setDisplayIdeasModal } from '../../redux/actions/modalsActions';
import { getDoc, doc } from 'firebase/firestore'
import { db } from '../../config/firebase-config'
import _ from 'lodash'
import { useToast } from '@chakra-ui/react';

function IdeasGenerator() {

  const toast = useToast()

  const dispatch = useDispatch()

  const { tasks, kanbanId } = useSelector(state => state.kanban)
  const { accessToken } = useSelector(state => state.user)

  const [isLoading, setIsLoading] = useState(true)
  const [generatedIdeas, setGeneratedIdeas] = useState([])

  const getOnlineArticles = async () => {
    let onlineArticles = []
    const scanDoc = await getDoc(doc(db, 'scans', kanbanId))
    const scanData = await scanDoc.data()
    if (scanData) {
      onlineArticles = scanData.articles
      if (scanData.ignoredArticles) {
        const ignoredUrls = scanData.ignoredArticles.map(a => a.url)
        onlineArticles = onlineArticles.filter(a => !ignoredUrls.includes(a.url) )
      }
    }
    const subjects = tasks.map(task => task.form.subject)

    if (subjects.length > 0 || onlineArticles.length > 0) {
      let subjectsToUse = subjects.concat(onlineArticles.map(a => a.title))
      if (subjectsToUse.length > 50) {
        const shuffledSubjects = _.shuffle(subjectsToUse)
        subjectsToUse = shuffledSubjects.slice(0, 50)
      }
      generateIdeasFromSubjects(subjectsToUse)
    } else {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getOnlineArticles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const generateIdeasFromSubjects = async (subjects) => {
      setIsLoading(true)
        try {
          const ideas = await getIdeasFromOpenai(subjects, null)
          setGeneratedIdeas(ideas)
          setIsLoading(false)
        } catch (error) {
          console.log(error)
        }
  }

  const getIdeasFromOpenai = async (subjects, themes) => {

    try {
      const wraityService = new WraityService(accessToken)
      const gptSettings = {
        tokens: 400,
        context: 'ideas',
        subjects: subjects,
        themes: themes
      }
      const ideas = await wraityService.generateIdeas(gptSettings)
      if (!ideas) throw new Error("Impossible de générer des idées")
      return Object.values(ideas)
    } catch (error) {
      console.log(error)
      setGeneratedIdeas([])
      setIsLoading(false)
      toast({
        title: 'Erreur lors de la génération des idées de sujets.',
        description: "Veuillez réessayer.",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      closeModal()
    }

  }

  const closeModal = () => {
    dispatch(setDisplayIdeasModal(false))
  }

  return (
    <Modal isLoading={isLoading} loadingText={"Génération de sujets en cours..."} closeModal={closeModal}>
      {generatedIdeas.length === 0 &&
        <IdeasForm
          getIdeasFromOpenai={getIdeasFromOpenai}
          setGeneratedIdeas={setGeneratedIdeas}
          setIsLoading={setIsLoading}
        />
      }
      {generatedIdeas.length > 0 &&
        <IdeasResults results={generatedIdeas}
          getOnlineArticles={getOnlineArticles}
        />
      }
    </Modal>
  )
}

export default IdeasGenerator
