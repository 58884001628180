import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Flex, Box, Spinner, Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton } from '@chakra-ui/react';
import ArticleSection from './Article';
import TaskForm from './Form';
import Nav from './Nav';
import SocialNetworksSection from "./SocialNetworks";
import { db } from '../../config/firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { setDisplayTaskModal } from '../../redux/actions/modalsActions';
import Modal from '../Modal';
import Meta from './Meta';
import TaskDelete from './Delete';

function Task() {
  const dispatch = useDispatch();
  const { kanbanId, selectedTaskId } = useSelector(state => state.kanban);

  const [task, setTask] = useState(null);
  const [activeSection, setActiveSection] = useState('edit');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getTaskData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const taskDoc = await getDoc(doc(db, 'kanbans', kanbanId, 'tasks', selectedTaskId));
        const data = taskDoc.data();
        if (data) {
          setTask(data);
          switch (data.status) {
            case 'to-do':
              setActiveSection('edit');
              break;
            case 'completed':
              setActiveSection('results');
              break;
            default:
              setActiveSection('edit');
          }
        } else {
          throw new Error('Task data not found');
        }
      } catch (error) {
        console.error('Error fetching task data:', error);
        setError('Failed to load task data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    if (kanbanId && selectedTaskId) {
      getTaskData();
    }
  }, [kanbanId, selectedTaskId]);

  const getModalContent = () => {
    if (isLoading) return <Spinner />;
    if (error) return (
      <Alert status="error">
        <AlertIcon />
        <Box flex="1">
          <AlertTitle>Error!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Box>
        <CloseButton position="absolute" right="8px" top="8px" onClick={() => setError(null)} />
      </Alert>
    );

    switch (activeSection) {
      case 'edit':
        return <TaskForm my={4} task={task} setTask={setTask} setIsLoading={setIsLoading} />;
      case 'social-networks':
        return <SocialNetworksSection my={4} task={task} />;
      case 'results':
        return <ArticleSection my={2} task={task} />;
      case 'meta':
        return <Meta task={task} />;
      case 'delete':
        return <TaskDelete kanbanId={kanbanId} task={task} />;
      default:
        return null;
    }
  };

  const closeModal = () => {
    dispatch(setDisplayTaskModal(false));
  };

  return (
    <Modal isLoading={isLoading} loadingText="" closeModal={closeModal}>
      <Flex mb={4} height="70px" alignItems="center" justifyContent="space-between" flexWrap={['wrap', 'nowrap']} mt={[4, 0, 0]}>
        <Nav width="100%" task={task} activeSection={activeSection} setActiveSection={setActiveSection} />
      </Flex>
      <Box height="calc(100% - 70px)" overflowY="auto">
        {getModalContent()}
      </Box>
    </Modal>
  );
}

export default Task;
