export const setDisplayTaskModal = (bool) => (
  {
    type: 'setDisplayTaskModal',
    payload: bool
  }
)

export const setDisplayIdeasModal = (bool) => (
  {
    type: 'setDisplayIdeasModal',
    payload: bool
  }
)

export const setDisplaySubscriptionModal = (bool) => (
  {
    type: 'setDisplaySubscriptionModal',
    payload: bool
  }
)

export const setDisplayExistingArticleModal = (bool) => (
  {
    type: 'setDisplayExistingArticleModal',
    payload: bool
  }
)

export const setDisplayTutorial = (bool) => (
  {
    type: 'setDisplayTutorial',
    payload: bool
  }
)

export const setDisplayScan = (bool) => (
  {
    type: 'setDisplayScan',
    payload: bool
  }
)

export const setDisplayApiModal = (bool) => (
  {
    type: 'setDisplayApiModal',
    payload: bool
  }
)
