import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import WraityService from "../../../services/wraity"
import MetaTag from "./MetaTag"
import { Box, Flex, Button, Text, Divider, useToast } from "@chakra-ui/react"
import { setDisplayTaskModal, setDisplaySubscriptionModal } from "../../../redux/actions/modalsActions"
import { onSnapshot, doc, setDoc } from "firebase/firestore"
import { db } from "../../../config/firebase-config"


function Metadata({task}) {

  const dispatch = useDispatch()
  const toast = useToast()

  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false)
  const [isGeneratingTitle, setIsGeneratingTitle] = useState(false)

  const [metatitle, setMetatitle] = useState(task.metatitle ? task.metatitle : null)
  const [metadesc, setMetadesc] = useState(task.metadesc ? task.metadesc : null)

  const { accessToken, hasTokens, hasBought } = useSelector(state =>  state.user)
  const { kanbanId } = useSelector(state => state.kanban)

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "kanbans", kanbanId, 'tasks', task.id), (doc) => {
      const data = doc.data()
        if (data !== undefined) {
          setMetatitle(data.metatitle ? data.metatitle : null)
          setMetadesc(data.metadesc ? data.metadesc : null)
          setIsGeneratingDescription(data.isGeneratingDescription ? data.isGeneratingDescription : false)
          setIsGeneratingTitle(data.isGeneratingTitle ? data.isGeneratingTitle : false)
        }
    })

    return () => {
      unsubscribe()
    }
  }, [kanbanId, task.id])

  const generateMetaDescription = async () => {
    if (hasTokens || hasBought) {
      setIsGeneratingDescription(true)
      try {
        const gptSettings = {
          context: 'metadata',
          tag: 'description',
          text: task.article,
          keywords: task.form.keywords
        }
        await setDoc(doc(db, 'kanbans', kanbanId, 'tasks', task.id), {isGeneratingDescription: true},{merge: true})
        const wraityService = new WraityService(accessToken)
        await wraityService.generateMetaData(gptSettings, kanbanId, task.id)
      } catch {
        setIsGeneratingDescription(false)
        errorToast()
      }
    } else {
      dispatch(setDisplayTaskModal(false))
      // TODO : display buy modal
      dispatch(setDisplaySubscriptionModal(true))
    }
  }

  const generateMetaTitle = async () => {
    if (hasTokens || hasBought) {
      setIsGeneratingTitle(true)
      try {
        const gptSettings = {
          context: 'metadata',
          tag: 'title',
          text: task.article,
          keywords: task.form.keywords
        }
        await setDoc(doc(db, 'kanbans', kanbanId, 'tasks', task.id), { isGeneratingTitle: true }, { merge: true })
        const wraityService = new WraityService(accessToken)
        await wraityService.generateMetaData(gptSettings, kanbanId, task.id)
      } catch (error) {
        console.log(error)
        errorToast()
      }
    } else {
      dispatch(setDisplayTaskModal(false))
      // TODO : display buy modal
      dispatch(setDisplaySubscriptionModal(true))
    }
  }

  const errorToast = () => {
    toast({
        title: 'Erreur',
        description: "Vérifiez votre connexion internet puis réessayez.",
        status: 'error',
        duration: 4000,
        isClosable: true,
    })
  }

  return (
    <Box h='100%' w='100%' overflowY='hidden'>
      <Flex>
        {(!metadesc || metadesc === "")  &&
          <Button mr={2} size="sm" colorScheme="switch" onClick={generateMetaDescription} isLoading={isGeneratingDescription}>
              Générer une balise de description
          </Button>
        }
        {(!metatitle || metatitle === "")  &&
          <Button mr={2} size="sm" colorScheme="switch" onClick={generateMetaTitle} isLoading={isGeneratingTitle}>
            Générer une balise de titre
          </Button>
        }
      </Flex>
      <Divider my={4}/>
      <Box w='100%'>
        {metadesc && metadesc !== "" &&
          <Box my={8} w='100%'>
            <Text fontSize='md' fontWeight='bold'>
              Description :
            </Text>
            <MetaTag taskId={task.id} tag='description' content={metadesc} generateMetaDescription={generateMetaDescription} isGeneratingDescription={isGeneratingDescription}/>
          </Box>
        }
        {metatitle && metatitle !== "" &&
          <Box my={8} w='100%'>
            <Text fontSize='md' fontWeight='bold'>
              Titre :
            </Text>
            <MetaTag taskId={task.id} tag='title' content={metatitle} generateMetaTitle={generateMetaTitle} isGeneratingTitle={isGeneratingTitle}/>
          </Box>
        }
      </Box>
    </Box>
  )
}

export default Metadata
