import { Button, Card, CardBody, Flex} from '@chakra-ui/react'
import styled from 'styled-components'


const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #c6c6c6;

  h1 {
    font-weight: bold;
    font-size: 40px;
  }
`

function ErrorPage() {

  return (
    <Container>
      <Card>
        <CardBody p={8}>
          <Flex alignItems='center' justifyContent='center' flexDirection='column'>
            <h1>Oops ! Une erreur est survenue 😬</h1>
            <Button my={4}  bg='brand.primary' color='white' _hover={{ background: "brand.secondary"}}>
              <a href='/'>Revenir en lieux sûrs</a>
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </Container>
  )
}

export default ErrorPage
