import { useState, useEffect } from "react"
import { useSelector } from 'react-redux'
import { Box, Textarea, Code, Button, Flex, Icon, Text } from "@chakra-ui/react"
import { RepeatIcon, EditIcon, CopyIcon } from '@chakra-ui/icons'
import { BsCodeSlash } from 'react-icons/bs';
import { db } from "../../../config/firebase-config"
import { doc, setDoc } from "firebase/firestore"

function MetaTag({taskId, tag, content, generateMetaDescription, generateMetaTitle, isGeneratingDescription, isGeneratingTitle}) {

  const [isCode, setIsCode] = useState(true)
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { kanbanId } = useSelector(state => state.kanban)

  useEffect(() => {
    setValue(content)
    if (tag === 'description') {
      setIsLoading(isGeneratingDescription)
    } else if (tag === 'title') {
      setIsLoading(isGeneratingTitle)
    }
  }, [content, isGeneratingDescription, isGeneratingTitle, tag])

  const handleRestart = async () => {
    if (tag === 'description') {
      await generateMetaDescription()
    } else if (tag === 'title') {
      await generateMetaTitle()
    }
  }

  const handleBlur = async () => {
    if (tag === 'description') {
      await setDoc(doc(db, 'kanbans', kanbanId, 'tasks', taskId), { metadesc: value }, {merge: true})
    } else {
      await setDoc(doc(db, 'kanbans', kanbanId, 'tasks', taskId), { metatitle: value }, {merge: true})
    }
  }

  const handleChange = (value) => {
    setValue(value)
  }

  const handleCopy = () => {
    if (isCode && tag === 'description') {
      navigator.clipboard.writeText(`<meta name="description" content="${value}"></meta>`)
    } else if (isCode && tag === 'title') {
      navigator.clipboard.writeText(`<title>${value}</title>`)
    } else {
      navigator.clipboard.writeText(value)
    }
  }


  return (
    <Box w='100%'>
      { isCode ?
        <Code p={4} opacity={isLoading ? 0.6 : 1}>
          {tag === 'description' ? `<meta name="description" content="${value}"></meta>` : `<title>${value}</title>`}
        </Code>
        :
        <Textarea p={1} value={value} disabled={isLoading ? true : false}
                  onChange={(e) => {handleChange(e.target.value)}}
                  onBlur={handleBlur}
        />
      }
      <Flex w='100%' alignItems='center' justifyContent='space-between'>
        <Text fontWeight='bold' fontSize='sm' color='gray'>Nombre de caratères : {value.split('').length}</Text>
        <Flex mt={2} justifyContent='flex-end'>
          <Button isLoading={isLoading} isDisabled={isLoading} mx={1} onClick={handleRestart} size='sm'>
            {!isLoading && <RepeatIcon mr={1}/>}
            Recommencer
          </Button>
          {isCode ?
            <Button isDisabled={isLoading} mx={1} onClick={() => {setIsCode(!isCode)}} size='sm' colorScheme="gray">
              <EditIcon mr={1} />
              Éditer
            </Button>
            :
            <Button isDisabled={isLoading} mx={1} onClick={() => {setIsCode(!isCode)}} size='sm' colorScheme="gray">
              <Icon as={BsCodeSlash} mr={1}/>
              Voir le code
            </Button>
          }
          <Button colorScheme='switch' isDisabled={isLoading} mx={1} onClick={handleCopy} size='sm'>
            <CopyIcon mr={1} />
            Copier
          </Button>
        </Flex>
      </Flex>
    </Box>
  )

}

export default MetaTag
