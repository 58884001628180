import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { setDisplayTutorial } from '../../redux/actions/modalsActions'
import { Box, Flex, Button, Text, UnorderedList, ListItem } from '@chakra-ui/react'
import { useState } from 'react'
import { db } from '../../config/firebase-config'
import { setDoc, doc } from 'firebase/firestore'
import step1 from '../../assets/tutorial/step01.gif'
import step2 from '../../assets/tutorial/step02.gif'
import step3 from '../../assets/tutorial/step03.gif'
import step4 from '../../assets/tutorial/step04.gif'
import step5 from '../../assets/tutorial/step05.gif'

const Wrapper = styled.div`
  background-color: rgba(0,0,0,0.8);
  width: 100vw;
  height: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Modal = styled.div`
  width: 80%;
  height: 600px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.2);
  overflow: hidden;

  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
  }
`

const ModalHeader = styled.div`
  width: 100%;
  height: 90px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    height: 130px;
  }
`

const ModalBody = styled.div`
  height: calc(100% - 160px);
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  img {
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    height: calc(100% - 270px);
    img {
      width: 100%;
    }
  }

`

const ModalFooter = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    img {
      height: 140px;
    }
  }
`

const StyledText = styled(Text)`
  color: gray;
  cursor: pointer;

  &:hover {
    color: black;
    font-weight: bold;
  }

`


function Tutorial() {

  const { uid } = useSelector(state => state.user)

  const steps = [
    {img: step1, title: 'Créer un nouvel article', text: ["Pour créer un nouvel article, cliquez sur le bouton 'Nouvel Article' dans la colonne 'À faire'.",  "Il ne vous reste plus qu'à renseigner votre sujet et appuyer sur la touche entrée de votre clavier.", "Vous pouvez aussi cliquer sur le petit bouton vert."]},
    {img: step2, title: "Configurer un article", text: ["Cliquez sur l'article fraîchement créé.", "Une fenêtre apparait et vous pouvez alors remplir le formulaire pour configurer votre article."]},
    {img: step3, title: "Générer un article", text: ["Une fois votre article configuré, il ne vous reste qu'à le faire glisser dans la colonne 'En cours' et patienter quelques instants.", "Une fois votre article généré, il sera automatiquement déplacé dans la colone 'Terminé'."]},
    {img: step4, title: 'Éditer un article', text: ["Cliquez sur un article dans la colonne 'Terminé'.", "Une fenêtre apparaît et vous pouvez alors éditer votre article.", "Si vous cliquez sur les actions rapides en bas à droite, attendez la fin du chargement avant de refermer la fenêtre."]},
    {img: step5, title: 'Obtenir des idées de sujets', text: ["Cliquez sur le bouton 'Générer des sujets à traiter' dans la colonne 'À faire'.", "Après un temps de chargement des sujets vous seront proposés.", "Cliquez sur 'nouvel article' sous chaque sujet qui vous intéresse et ces articles seront ajoutés dans la colonne 'À faire'."]}
  ]

  const dispatch = useDispatch()

  const [currentStep, setCurrentStep] = useState(0)

  const closeTutorial = async () => {
    await setDoc(doc(db, 'users', uid), {showTutorial: false}, {merge: true})
    dispatch(setDisplayTutorial(false))
  }

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    }

  }

  const handlePrev = () => {
    if (currentStep > 0 ) {
      setCurrentStep(currentStep - 1)
    }
  }

  return(
    <Wrapper>
      <Modal closeModal={closeTutorial} >
        <ModalHeader>
          <Box>
            <Text fontWeight='bold' fontSize={['lg', 'xl', '2xl']}>Bienvenue sur CopyWraiter 👋</Text>
            <Text fontSize='lg' color='gray.600' my={1}>Voici une courte présentation des fonctionnalités essentielles.</Text>
          </Box>
        </ModalHeader>
        <ModalBody>
          <Flex alignItems='center' w='100%' flexDirection={['column', 'column', 'row']}>
            <Box w={['100%', '100%', '60%']}>
              <img src={steps[currentStep].img} alt="" />
            </Box>
            <Flex flexDirection='column' alignItems='center' w={['100%', '100%', '40%']} p={6}>
              <Text fontSize='xl' fontWeight='bold'>{ `Étape ${currentStep + 1} : ` + steps[currentStep].title}</Text>
              <UnorderedList>
                {steps[currentStep].text.map(item => (
                  <ListItem my={2}>{item}</ListItem>
                ))}
              </UnorderedList>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <StyledText onClick={closeTutorial}>Ignorer le tutoriel</StyledText>
          <Text color='gray.600'> {currentStep + 1} / 5</Text>
          <Box my={[4, 0, 0]}>
            <Button mr={4} onClick={handlePrev}>
              Précédent
            </Button>
            {currentStep < steps.length - 1 ?
              <Button onClick={handleNext}>
                Suivant
              </Button>
              :
              <Button colorScheme='green' onClick={closeTutorial}>
                Terminé
              </Button>
            }
          </Box>
        </ModalFooter>
      </Modal>
    </Wrapper>
  )
}

export default Tutorial
