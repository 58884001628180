import { combineReducers } from "redux"
import { createStore } from 'redux'

import kanbanReducer from "./reducers/kanbanReducer"
import userReducer from "./reducers/userReducer"
import modalsReducer from "./reducers/modalsReducer"

const rootReducer = combineReducers({
  kanban: kanbanReducer,
  user: userReducer,
  modals: modalsReducer
})

const store = createStore(rootReducer)

export default store
