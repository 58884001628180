import { useState, useEffect } from "react";
import { Button, Flex, Icon, Box, Text, Tooltip } from "@chakra-ui/react"
import { RepeatIcon, CopyIcon } from "@chakra-ui/icons";
import { useSelector, useDispatch } from 'react-redux'
import {BsArrowsExpand} from 'react-icons/bs'
import {CgCompressV} from 'react-icons/cg'
import {TbTriangleSquareCircle} from 'react-icons/tb'
import { setDisplaySubscriptionModal, setDisplayTaskModal } from '../../../redux/actions/modalsActions'
import WraityService from "../../../services/wraity";
import { db } from "../../../config/firebase-config";
import { doc, setDoc } from "firebase/firestore";

const actions = [
  { key: 'develop' , title: 'Developper', icon: <Icon mr={1} as={BsArrowsExpand}/>, available: true, tooltip: "Rallonge l'article en conservant la structure actuelle. Rajoute parfois une conclusion et/ou des sous-parties."},
  { key: 'reformulate' , title: 'Reformuler', icon: <RepeatIcon mr={1}/>, available: true, tooltip: "Modifie légèrement l'article en le reformulant."},
  { key: 'synthesis' , title: 'Synthétiser', icon: <Icon mr={1} as={CgCompressV}/>, available: false},
  { key: 'simplify' , title: 'Simplifier', icon: <Icon mr={1} as={TbTriangleSquareCircle}/>, available: false},
  { key: 'copy', title: 'Copier', icon: <CopyIcon mr={1}/>, available: true},
]


function ArticleActions({value, task}) {

  const dispatch = useDispatch()

  const [availableActions, setAvailableActions] = useState([])
  const [wordsCount, setWordsCount] = useState(0)

  const { accessToken, hasTokens, hasBought } = useSelector((state) => state.user)
  const { kanbanId, tasks, scanData } = useSelector(state => state.kanban)

  useEffect(() => {
    const data = actions.filter(action => action.available)
    setAvailableActions(data)
  }, [])

  useEffect(() => {
    setWordsCount(countWords(value))
  }, [value])

  const countWords = (htmlString) => {
    const regex = /(<([^>]+)>)/ig;
    const textString = htmlString.replace(regex, '');
    const finalString = textString.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g," ")

    return finalString.split(' ').length
  }

  const handleAction = async (action) => {
    if (action === 'copy') {
      navigator.clipboard.writeText(value)
    } else if (hasTokens || hasBought) {
      dispatch(setDisplayTaskModal(false))
      await setDoc(doc(db, 'kanbans', kanbanId, 'tasks', task.id), {status: 'in-progress'}, {merge: true})
      task.context = 'actions'
      task.action = action
      const wraityService = new WraityService(accessToken)
      await wraityService.handleArticleAction(task, tasks, scanData, kanbanId)
    } else {
      dispatch(setDisplayTaskModal(false))
      // TODO : display buy modal
      dispatch(setDisplaySubscriptionModal(true))
    }
  }

  return (
    <Flex  h='50px' w='100%' justifyContent='space-between' alignItems='center' flexWrap='wrap'>
      <Box>
        <Text fontWeight='semibold' color='gray'>
          Nombre de mots : {wordsCount}
        </Text>
      </Box>
      <Flex h='100%' alignItems='center' justify='flex-end'>
        {availableActions.map((action, index) => {
          if (action.tooltip) {
            return(
              <Tooltip key={index} label={action.tooltip} placement='top' bg='gray.100' color='gray.600'>
                <Button
                      size='sm' mx={1}
                      colorScheme={action.key === 'copy' ? 'switch' : 'gray'}
                      onClick={() => handleAction(action.key)}
                >
                  {action.icon}
                  <span>{action.title}</span>
                </Button>
              </Tooltip>
            )
          } else {
            return(
              <Button key={index}
                      size='sm' mx={1}
                      colorScheme={action.key === 'copy' ? 'switch' : 'gray'}
                      onClick={() => handleAction(action.key)}
              >
                {action.icon}
                <span>{action.title}</span>
              </Button>
            )
          }
        })}
      </Flex>
    </Flex>
  );
}

export default ArticleActions
