import { Flex, Button, Input } from "@chakra-ui/react"
import { CheckIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { setDoc, doc } from "firebase/firestore"
import { db } from "../../config/firebase-config"




function KanbanCard({title, id, setIsDeletingKanban, setKanbanToDelete}) {

  const navigate = useNavigate()

  const [isEditable, setIsEditable] = useState(false)
  const [value, setValue] = useState(title)
  const [isLoading, setIsLoading] = useState(false)

  const handleKanbanClick = () => {
    navigate(`/app/kanban/${id}`)
  }

  const handleEditClick = (e) => {
    e.stopPropagation()
    setIsEditable(true)
  }

  const handleDeleteClick = (e) => {
    e.stopPropagation()
    setKanbanToDelete({id, title})
    setIsDeletingKanban(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    await setDoc(doc(db, 'kanbans', id), {name: value}, {merge: true})
    setIsEditable(false)
    setIsLoading(false)
  }

  const handleInputChange = (e) => {
    setValue(e.target.value)
  }

  if (isEditable) {
    return (
      <Flex cursor="pointer" p={4} w={['95%', '400px', '400px']} my={4}
          backgroundColor='white' alignItems='center' justifyContent='space-between'
          boxShadow='base' borderRadius={8} >
        <form onSubmit={(e) => {handleSubmit(e)}}>
          <Input w={['90%', '250px', '300px']} value={value} onChange={(e) => {handleInputChange(e)}} mr={2} />
        </form>
        <Button colorScheme="green" isLoading={isLoading} onClick={(e) => {handleSubmit(e)}}>
            <CheckIcon />
        </Button>
      </Flex>
    )
  } else {
    return (
      <Flex cursor="pointer" p={4} w={['95%', '600px', '600px']} my={4}
          backgroundColor='white' alignItems='center' justifyContent='space-between'
          boxShadow='base' borderRadius={8}
          onClick={handleKanbanClick}
          >
          {value}
          <Flex>
            <Button colorScheme="gray" onClick={(e) => {handleEditClick(e)}} mx={1}>
              <EditIcon />
            </Button>
            <Button colorScheme="gray" onClick={(e) => {handleDeleteClick(e)}} mx={1}>
              <DeleteIcon />
            </Button>
          </Flex>
        </Flex>
    )
  }
}

export default KanbanCard
