import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react'
import { Card, CardBody } from '@chakra-ui/react'
import { IconButton } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

const StyledForm = styled.form`
    height: 100%;
    width: 100%;

    input {
      border: none;
      height: 100%;
      width: 100%;
      font-family: 'Lato', sans-serif;
      &:focus {
        outline: none;
      }
  }

`

const StyledCardBody = styled(CardBody)`
  p {
    max-width: 85%;
  }
`



const TaskCard = ({children, newCard, createTask}) => {


  const [taskTitle, setTaskTitle] = useState('')
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current?.focus()
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    createTask(taskTitle)
  }

    return (
      <Card size='sm'>
        <StyledCardBody display="flex" alignItems="center" justifyContent="space-between">
          { newCard ?
          <>
            <StyledForm onSubmit={(e) => {handleSubmit(e)}}>
              <input ref={inputRef} placeholder='Le sujet de votre article...' name='task' type="text" value={taskTitle} onChange={(e) => {setTaskTitle(e.target.value)}} />
            </StyledForm>
            <IconButton colorScheme='green' onClick={() => {createTask(taskTitle)}} icon={<AddIcon />} />
          </>
          :
          <>
            {children}
          </>
          }
        </StyledCardBody>
      </Card>
    )



}

export default TaskCard
